function defaultDisabled() {
  return false;
}

function defaultHandler(pair: IPair) {
  return pair;
}

function numberValue(pair:IPair){
  return {id:pair.id, value:parseInt(pair.value)}
}

const model = {
  header: {
    productName: { id: "productName", name: "", config: {} },
    plan: { id: 'properties.Coverage Type', name: '', config: {} },
    items: [
      {
        current: {
          id: "properties.Price.Total",
          name: "Total Price",
          config: { currency: true },
        },
        prev: {
          id: "properties.Previous Price.Total",
          name: "",
          config: { currency: true, className: "cross-line gray bold" },
        },
        config: {},
      },
    ],
  },
  body: {
    items: [
      {
        id: "Price Breakdown",
        name: "Price Breakdown",
        items: [
          {
            current: {
              id: "properties.Price.Main Policy",
              name: "Gross Premium",
              config: { currency: true },
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.Price.Admin Fee",
              name: "Admin Fee",
              config: { currency: true },
            },
            prev: { config: {} },
            config: {},
          },
        ],
        config: {},
      },
      {
        id: 'Commission Breakdown',
        name: 'Commission Breakdown',
        items: [
          {
            current: { id: 'properties.Commission Amount', name: 'Commission Amount', config: { currency: true } },
            prev: { id: 'properties.Previous Commission', name: 'Commission Amount', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
      {
        id: "Other Details",
        name: "Other Details",
        items: [
          {
            current: {
              id: "properties.Product Type",
              name: "Product Type",
              config: {isEditable: false},
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.Sum Insured",
              name: "Sum Insured",
              type: "Input",
              disabled: defaultDisabled,
              handler: numberValue,
              config: {},
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.Coverage Type",
              name: "Coverage Type",
              type: "Select",
              collectionId: "coverage_types",
              disabled: defaultDisabled,
              handler: defaultHandler,
              config: {},
            },
            prev: { config: {} },
            config: {},
          },
          {
            current: {
              id: "properties.Cubic Capacity",
              name: "Cubic Capacity",
              type: "Input",
              disabled: defaultDisabled,
              handler: numberValue,
              config: {},
            },
            prev: { config: {} },
            config: {},
          },
        ],
        config: { isEditable: true },
      },
    ],
  },
};

export default model;
