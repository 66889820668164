import { modelId } from "../../Products/shared/utils/modelUtil";

const mainInsuredBasedPath = "properties.Main Insured";
const insuredDetailBasePath = "properties.Insured Property";
const beneficiariesDetailsBasePath = "properties.beneficiaries";

function defaultDisabled() {
  return false;
}

function defaultHandler(pair: any) {
  return pair;
}

const model = (type: "Individual" | "Company") => {
  const getMandatoryIndividualField = (basePath: string) => {
    return type === "Individual"
      ? [
          {
            id: modelId(basePath, "Individual ID Type"),
            name: "ID Type",
            type: "Select",
            collectionId: "idTypes",
            disabled: defaultDisabled,
            handler: defaultHandler,
            placeholder: `Select Option`,
            config: {},
          },
          {
            id: modelId(basePath, "Individual ID Number"),
            name: "ID Number",
            type: "Input",
            disabled: defaultDisabled,
            handler: defaultHandler,
            placeholder: `Enter ID number`,
            config: {},
          },
        ]
      : null;
  };

  const getMandatoryCompanyField = (basePath: string) => {
    return type === "Company"
      ? [
          {
            id: modelId(basePath, "Company VATIN"),
            name: "VAT TIN",
            type: "Input",
            disabled: defaultDisabled,
            handler: defaultHandler,
            placeholder: `Enter VAT TIN`,
            config: {},
          },
        ]
      : null;
  };

  const getIndividualOrCompanyDetail = (basePath: string) => {
    return [
      {
        id: modelId(basePath, `${type} Name`),
        name: `${type === "Individual" ? "Full" : "Company"} Name`,
        type: "Input",
        disabled: defaultDisabled,
        handler: defaultHandler,
        placeholder: `Enter ${type === "Individual" ? "Full" : "Company"} Name`,
        config: {},
      },
      ...(getMandatoryCompanyField(basePath) || []),
      {
        id: modelId(basePath, `${type} Business Occupation`),
        name: `Business Occupation`,
        type: "Input",
        disabled: defaultDisabled,
        handler: defaultHandler,
        placeholder: `Enter Business Occupation`,
        config: {},
      },
      ...(getMandatoryIndividualField(basePath) || []),
    ];
  };

  const getContactDetails = (basePath: string) => {
    return [
      {
        id: modelId(basePath, `${type} Address`),
        name: `Address`,
        type: "Input",
        disabled: defaultDisabled,
        handler: defaultHandler,
        placeholder: `Enter address`,
        config: {},
      },
      {
        id: modelId(basePath, `${type} Country`),
        name: "Country",
        type: "Select",
        collectionId: "countries",
        disabled: defaultDisabled,
        handler: defaultHandler,
        placeholder: `Select Option`,
        config: {},
      },
      {
        id: modelId(basePath, `${type} City`),
        name: "City",
        type: "Input",
        disabled: defaultDisabled,
        handler: defaultHandler,
        placeholder: `Enter city`,
        config: {},
      },
      {
        id: modelId(basePath, `${type} State`),
        name: "State",
        type: "Input",
        disabled: defaultDisabled,
        handler: defaultHandler,
        placeholder: `Enter state`,
        config: {},
      },
      {
        id: modelId(basePath, `${type} Postcode`),
        name: "Postcode",
        type: "Input",
        disabled: defaultDisabled,
        handler: defaultHandler,
        placeholder: `Enter postcode`,
        config: {},
      },
      {
        id: modelId(basePath, `${type} Email Address`),
        name: "Email Address",
        type: "Input",
        disabled: defaultDisabled,
        handler: defaultHandler,
        placeholder: `Enter email address`,
        config: {},
      },
      {
        id: modelId(basePath, `${type} Mobile Number`),
        name: "Mobile Number",
        type: "Input",
        disabled: defaultDisabled,
        handler: defaultHandler,
        placeholder: `Enter mobile number`,
        config: {},
      },
    ];
  };

  return {
    body: {
      items: {
        main: {
          id: "properties.Main Insured",
          items: getIndividualOrCompanyDetail(mainInsuredBasedPath),
          config: { editable: true },
        },
        contactDetails: {
          id: "properties.Main Insured",
          items: getContactDetails(mainInsuredBasedPath),
          config: { editable: true },
        },
        insuredPropertyDetails: {
          id: "properties.Insured Property",
          items: [
            {
              id: modelId(insuredDetailBasePath, `Registration No`),
              name: `Registration No`,
              type: "Input",
              disabled: defaultDisabled,
              handler: defaultHandler,
              placeholder: `Enter Registration No`,
              config: {},
            },
            {
              id: modelId(insuredDetailBasePath, `Make/Model`),
              name: "Make/Model",
              type: "Input",
              disabled: defaultDisabled,
              handler: defaultHandler,
              placeholder: `Enter make/model`,
              config: {},
            },
            {
              id: modelId(insuredDetailBasePath, `Chassis No/Engine No`),
              name: "Chassis No./Engine No.",
              type: "Input",
              disabled: defaultDisabled,
              handler: defaultHandler,
              placeholder: `Enter Chassis No./Engine No.`,
              config: {},
            },
            {
              id: modelId(insuredDetailBasePath, `Number of Seats`),
              name: "Number of Seats",
              type: "Input",
              disabled: defaultDisabled,
              handler: defaultHandler,
              placeholder: `Enter number of seats`,
              config: {},
            },
            {
              id: modelId(insuredDetailBasePath, `Year of Manufacture`),
              name: "Year of Manufacture",
              type: "Input",
              disabled: defaultDisabled,
              handler: defaultHandler,
              placeholder: "Enter year of manufacture",
              config: {},
            },
          ],
          config: { editable: true },
        },
        beneficiaries: {
          id: "properties.beneficiaries",
          items: [
            ...getIndividualOrCompanyDetail(beneficiariesDetailsBasePath),
            ...getContactDetails(beneficiariesDetailsBasePath),
          ],
          config: { editable: true },
        },
      },
      config: {},
    },
  };
};

export default model;
