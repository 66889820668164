import React from "react";
import { connect } from "react-redux";
import Notifications from 'react-notification-system-redux';
import { Button, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';

import {utils} from 'utils';
import config from '_config';
import { store } from '_helpers';
import { SHOW_FORBIDDEN_MESSAGE } from '_constants';
import { policyActions } from "Products/shared/policyListing/actions";
import EndorseForm from "Products/shared/policyListing/components/EndorseForm";
import CancelForm from "Products/shared/policyListing/components/CancelForm";

import { TitleComponent } from "_components";
import getQuoteGraphic from "../../../images/bannerGraphicImages/get-quote.png"
import Edit from 'Products/CashForHospitalization/policy/edit';
import { sidebarModel, DetailModel } from 'models';
import BasePolicyDetail from 'Products/CBTravelInsurance/basePolicyDetail';
import { DetailTitle, SidebarCard, PaymentCard, InsuredDetail } from '@xc-core/components';

class PolicyDetail extends BasePolicyDetail {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator(utils.validations.alfalahValidationObject);
    this.beneficiaryValidator = new SimpleReactValidator(utils.validations.alfalahValidationObject);
    this.state = {
      form: props.policy,
      paymentDetailForm: props.paymentDetailForm,
      initialPaymentDetailForm: props.paymentDetailForm,
      dropdownOpen: false,
      endorseModalIsOpen: false,
      cancelModalIsOpen: false,
      endorseMode: false,
      sendingRequest: false,
      endorseRemarkIsValid: true,
    }
  }

  componentWillMount() {
    const backPath = this.props.history.location.pathname.split('/');
    this.setState({backPath: [...backPath.slice(0,2)].join('/')})
  }

  async componentDidMount() {
    const { policy } = this.props;
    const country = policy && policy.properties.Country ?
    policy.properties.Country : config.products.walletInsurance.defaultValues.country;
    await this.props.getStates(country, '?collection=state_alf');
  }

  componentWillReceiveProps(newProps) {
    const { endorseModalIsOpen, cancelModalIsOpen } = this.state;
    const { notification, notificationType } = newProps;
    if (this.props.policyDetailBlob === undefined && newProps.policyDetailBlob) {
      const url = window.URL.createObjectURL(new Blob([newProps.policyDetailBlob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `policy-${newProps.policy.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      this.props.clearBlobPolicy();
    }
    if (notification) this.props.notify(notification, notificationType);
    if (endorseModalIsOpen) this.setState(Object.assign({}, { endorseModalIsOpen: false, endorseMode: false }))
    if (cancelModalIsOpen) this.setState(Object.assign({}, { cancelModalIsOpen: false }))
  }

  toggleEndorseModal = () => {
    const { endorseModalIsOpen, form  } = this.state;
    this.setState({
      ...this.state,
      form: {
        ...form,
        properties: {
          ...form.properties,
          "Endorse Remarks": "",
        }
      }
    }, () => {
      if (endorseModalIsOpen === false) {
        if (this.validator.allValid() && this.beneficiaryValidator.allValid()) {
          this.setState({ ...this.state, endorseModalIsOpen: !endorseModalIsOpen,
            endorseRemarkIsValid: true});
        } else {
          this.validator.showMessages();
          this.beneficiaryValidator.showMessages();
          this.forceUpdate();
        }
      } else {
        this.setState({ ...this.state, endorseModalIsOpen: !endorseModalIsOpen,
          endorseRemarkIsValid: true});
      }
    });
  }

  endorseCertificate = async () => {
    // const isPermitted = await this.props.endorsePermission();
    // if(!isPermitted) {
    //   store.dispatch({type: SHOW_FORBIDDEN_MESSAGE});
    //   return;
    // }
    this.setState({
      ...this.state,
      endorseMode: true,
    });
  } // end of toggleEditMode

  cancelEndorseMode = () => {
    this.setState({endorseMode:false});
  }

  reissuePolicy = async (id) => {
   const isPermitted = await this.props.reissuePermission();
   if(!isPermitted) {
     store.dispatch({type: SHOW_FORBIDDEN_MESSAGE});
     return;
   }else {
    this.props.reissuePolicy(this.props.policy.id);
   }
 } // end of reissuePolicy

  handleChange = (e, isBeneificiary) => {
    let key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;
		if (e instanceof Date) {
			key = 'DOB';
      value = e;
      /** check if selected birthday is greater than maximum age */
      value = utils.lib.isAfter(value, config.products.cashForHospitalization.getQuote.maxDob) ? 
              config.products.cashForHospitalization.getQuote.maxDob : value;
      /**check if selected birthday is less than minimum age */
      value = utils.lib.isAfter(config.products.cashForHospitalization.getQuote.minDob ,value) ? 
              config.products.cashForHospitalization.getQuote.minDob : value;
    }
    if (key === 'Country') {
      /**when the country has changed send the request otherwise the states are same as before */
      if(this.state.form.properties[key] !== value) {
        this.props.getStates(value, '?collection=state_alf');
      }
    }

    if (isBeneificiary) {
      this.setState({
        ...this.state,
        form: {
          properties: { 
            ...this.state.form.properties,
            Beneficiaries: [
              {
                ...this.state.form.properties.Beneficiaries[0],
                [key]: value,
              }
            ]
          },
          endorseRemarkIsValid: true,
        }
      });
    } else {
      this.setState({
        ...this.state,
        form: {
          properties: { ...this.state.form.properties, [key]: value }
        },
        endorseRemarkIsValid: true,
      });
    }
  }

  handleIdTypeChange = (e, isBeneificiary) => {
    const { form } = this.state;
    const key = e && e.target && e.target.id;
    const value = e && e.target && e.target.value;
    if (isBeneificiary) {
      this.setState({
        ...this.state,
        form: {
          properties: {
            ...form.properties,
            Beneficiaries: [
              {
                ...form.properties.Beneficiaries[0],
                [key]: value,
                "ID Number": "",
              },
            ],
          },
        },
        endorseRemarkIsValid: true,
      });

    } else {
      this.setState({
        ...this.state,
        form: {
          properties: {
            ...form.properties,
            [key]: value,
            "ID Number": "",
          },
        },
        endorseRemarkIsValid: true,
      });
    }
  }

  handleIdNumberChange = (e, isBeneificiary) => {
    if (isBeneificiary) this.handleBeneficiaryIdNumberChange(e);
    else this.handleMainIdNumberChange(e);
  }

  handleMainIdNumberChange = (e) => {
    const { form } = this.state;
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;
    let gender = form.properties.Gender;

    if (form.properties["ID Type"] === "CNIC") {
      value = utils.lib.isOnlyNumber(value) || value === "" ? value : form.properties[key];
      value = value && value.length > utils.lengths.CNIC
        ? value.slice(0, utils.lengths.CNIC) : value;
      /** autofill gender based on cnic number */
      gender = value && value.length === utils.lengths.CNIC
        ? utils.lib.getGenderFromN(value[utils.lengths.CNIC-1]) : gender;
    } else {
      value = value === "" || utils.lib.isOnlyNumberOrAlphabet(value) ? value : form.properties["ID Number"];
    }

    this.setState({
      ...this.state,
      form: {
        properties: {
          ...form.properties,
          [key]: value,
          Gender: gender,
        },
      },
      endorseRemarkIsValid: true,
    });
  }

  handleBeneficiaryIdNumberChange = (e) => {
    const { form } = this.state;
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;

    if (form.properties.Beneficiaries[0]["ID Type"] === "CNIC") {
      value = utils.lib.isOnlyNumber(value) || value === "" ? value : form.properties.Beneficiaries[0][key];
      value = value && value.length > utils.lengths.CNIC
        ? value.slice(0, utils.lengths.CNIC) : value;
    } else {
      value = value === "" || utils.lib.isOnlyNumberOrAlphabet(value) ? value : form.properties.Beneficiaries[0]["ID Number"];
    }

    this.setState({
      ...this.state,
      form: {
        properties: {
          ...form.properties,
          Beneficiaries: [
            {
              ...form.properties.Beneficiaries[0],
              [key]: value,
            },
          ],
        },
      },
    });
  }

  handleNumberFormatFields = (e, isBeneificiary) => {

    if (isBeneificiary) this.handleBeneficiaryNumberFormatFields(e);
    else this.handleMainNumberFormatFields(e);
  }

  handleMainNumberFormatFields = (e) => {
    const { form } = this.state;
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;
    value = utils.lib.isOnlyNumber(value) || value === "" ? value : form.properties[key];

    this.setState({
      ...this.state,
      form: {
        properties: { ...form.properties, [key]: value },
      },
      endorseRemarkIsValid: true,
    });
  }

  handleBeneficiaryNumberFormatFields = (e) => {
    const { form } = this.state;
    const key = e && e.target && e.target.id;
    let value = e && e.target && e.target.value;
    value = utils.lib.isOnlyNumber(value) || value === "" ? value : form.properties.Beneficiaries[0][key];

    this.setState({
      ...this.state,
      form: {
        properties: {
          ...form.properties,
          Beneficiaries: [
            {
              ...form.properties.Beneficiaries[0],
              [key]: value,
            }
          ]
        }
      },
      endorseRemarkIsValid: true,
    });
  }

  toggleDropDown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleBackToListing = () => {
    this.props.history.push(`/policies`);
  }

  handleSubmitPayment = () => {
    const { policy } = this.props;
    const { paymentDetailForm } = this.state;
    this.props.updatePolicy(policy.id, paymentDetailForm, true);
    this.setState({
      initialPaymentDetailForm: paymentDetailForm, // the initial is being used for cancel,
      // and after every submitting this initial
      // should be updated
    });
  }

  handlePaymentInputChange = (e) => {
    this.setState({
      ...this.state,
      paymentDetailForm: {
        properties: { ...this.state.paymentDetailForm.properties, [e.target.id]: e.target.value }
      }
    });
  }

  handleSubmitEndorse = async () => {
    const { policy } = this.props;
    const { form } = this.state;

    if (this.validator.allValid() && this.beneficiaryValidator.allValid()
          && form.properties["Endorse Remarks"]) {
      this.setState({ sendingRequest: true });
      await this.props.updatePolicy(policy.id, this.preparePropertiesForServer());
      this.setState({ sendingRequest: false, endorseRemarkIsValid: true });
    } else if (form.properties["Endorse Remarks"]) {
      this.validator.showMessages();
      this.beneficiaryValidator.showMessages();
      this.toggleEndorseModal();
    } else {
      this.setState({endorseRemarkIsValid: false});
    }
  }
  
  preparePropertiesForServer = () => {
    const { form : {properties} } = this.state;
    return {
      properties: {
        "Full Name": properties["Full Name"],
        "CNIC Number": properties["CNIC Number"],
        "Gender": properties["Gender"],
        "Address": properties["Address"],
        "Country": properties["Country"],
        "State": properties["State"],
        "Postcode": properties["Postcode"],
        "Debit Card or Account No": properties["Debit Card or Account No"],
        "City": properties["City"],
        "Email Address": properties["Email Address"],
        "Contact Number": properties["Contact Number"],
        "Beneficiary Name": properties["Beneficiary Name"],
        "Relationship": properties["Relationship"],
        "Endorse Remarks": properties["Endorse Remarks"],
        'BCC Email': properties['BCC Email'],
        'CC Email': properties['CC Email'],
      },
    };
  }

  resetPaymentDetailsForm= () => {
    this.setState((prevState) => ({
      paymentDetailForm: prevState.initialPaymentDetailForm,
    }));
  }

  render() {
    this.validator.purgeFields(); /**this is for policy endorsement */
    this.beneficiaryValidator.purgeFields();
    
    const { policy, notificationType, detailCollections, downloadPolicy } = this.props;
    const { form, paymentDetailForm, endorseModalIsOpen, cancelModalIsOpen, endorseMode } = this.state;

    return (
      <div className="container-fluid px-0">
        <TitleComponent 
          title="Certificate Details"
          bannerGraphic={getQuoteGraphic}
          backTitle="Back to listing"
          backPath={this.state.backPath}
          history={this.props.history}
        >
        </TitleComponent>       
        <div className="row d-flex justify-content-center my_50" id="quotation-detail">
          <Col sm={11} className="quotation-detail-wrapper">
            { policy === null ? <div className={"loader d-block"} /> :
                (
                  <Row>
                    <Col sm={8}>
                      <DetailTitle.Policy policy={policy}>
                        <h5 className='text-medium-qc font-size-large'>{`Base Policy: ${policy.originalRefCode}`}</h5>
                      </DetailTitle.Policy>
                      {endorseMode === false
                        ? (
                          <InsuredDetail
                            item={{
                              insureds: [{ ...policy.properties }],
                              beneficiaries: policy.properties.Beneficiaries,
                            }}
                            model={DetailModel.cashAlfalah}
                          />
                        ) : (
                          <Edit
                            properties={form.properties}
                            collections={detailCollections}
                            handleChange = {(e, isBeneificiary) => this.handleChange(e, isBeneificiary)}
                            handleIdTypeChange = {(e, isBeneificiary) => this.handleIdTypeChange(e,isBeneificiary)}
                            handleIdNumberChange = {(e, isBeneificiary) => this.handleIdNumberChange(e,isBeneificiary)}
                            handleNumberFormatFields = {(e, isBeneificiary) => this.handleNumberFormatFields(e, isBeneificiary)}
                            validator={this.validator}
                            beneficiaryValidator={this.beneficiaryValidator}
                          />
                        )
                      }
                      <div className="text-right">
                        { endorseMode
                        ? (
                          <div>
                            <Button
                              color="muted"
                              className="my-4 mr-4 customLeastbtn"
                              onClick={this.cancelEndorseMode}
                            >
                              Cancel
                            </Button>
                            <Button
                              color="primary"
                              className="my-4 customPrimarybtn"
                              onClick={this.toggleEndorseModal.bind(this)}
                            >
                              Update & Reissue Certificate
                            </Button>
                          </div>
                        ) : (
                          <Button
                            color="info"
                            className="my-4 customInfobtn"
                            onClick={this.handleBackToListing.bind(this)}
                          >
                            Back to Listing
                          </Button>
                        )
                        }
                      </div>
                    </Col>
                    <Col sm={4}>
                      { endorseMode || notificationType === 'error' ? null : (
                        <Dropdown className="mb-3 text-right" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                          <DropdownToggle caret className='btn-action btn-action-primary'>
                            Actions
                          </DropdownToggle>
                          <DropdownMenu>
                            { this.downloadable(policy) ? <DropdownItem onClick={() => downloadPolicy(policy.id)}>Download Certificate</DropdownItem> : <></>}
                            { this.downloadable(policy) === false ? <DropdownItem onClick={() => downloadPolicy(policy.id)}>Download Cancellation Confirmation</DropdownItem> : <></>}
                            { this.reissueable(policy) ? <DropdownItem onClick={this.reissuePolicy}>Reissue Certificate</DropdownItem> : <></> }
                            { this.endorseable(policy) ? <DropdownItem onClick={this.endorseCertificate}>Endorse Certificate</DropdownItem> : <></>}
                            { this.cancelable(policy, ['In Effect']) ? <DropdownItem className='text-danger' onClick={this.toggleCancelModal}>Cancel Certificate</DropdownItem> : <></>}
                          </DropdownMenu>
                        </Dropdown>
                      )}
                      <SidebarCard.Policy
                          model={sidebarModel.policy.cashAlfalah}
                          status={policy.status}
                          item={policy}
                          customIconProduct={'icon-hospital'}
                      />
                      <PaymentCard
                        validator={this.validator}
                        resetPaymentDetailsForm={this.resetPaymentDetailsForm}
                        handleSubmit={this.handleSubmitPayment}
                        item={paymentDetailForm.properties}
                        collections={detailCollections}
                        handleInputChange={this.handlePaymentInputChange}
                        notification={this.props.notification}
                      />

                    </Col>
                    <EndorseForm
                      properties={form.properties}
                      isOpen={endorseModalIsOpen}
                      toggleModal={this.toggleEndorseModal.bind(this)}
                      handleInputChange={this.handleChange}
                      handleSubmit={this.handleSubmitEndorse}
                      disableSubmit={this.state.sendingRequest}
                      endorseRemarkIsValid={this.state.endorseRemarkIsValid}
                      hasAdditionalEmails
                    />
                    <CancelForm
                      isOpen={cancelModalIsOpen}
                      toggleModal={this.toggleCancelModal.bind(this)}
                      properties={form.properties}
                      policy={this.props.policy}
                      hasAdditionalEmails
                    />
                  </Row>
                )
            }
          </Col>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.clearPolicyDetail();
  }
}

PolicyDetail.defaultProps = {
  policy: null
}

function mapStateToProps(state) {
  const {
    policy,
    policyForm,
    notification,
    notificationType,
    policyDetailBlob,
    paymentDetailForm,
    detailCollections,
  } = state.policyManagement;

  return {
    policy,
    policyForm,
    notificationType,
    notification,
    policyDetailBlob,
    paymentDetailForm,
    detailCollections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // getDetail: policyId => dispatch(policyActions.getDetail(policyId)),
    reissuePolicy: policyId => dispatch(policyActions.reissuePolicy(policyId)),
    updatePolicy: (policyId, properties, updatePayment=false) => dispatch(policyActions.updatePolicy(policyId, properties, updatePayment)),
    downloadPolicy: policyId => dispatch(policyActions.downloadPolicy(policyId)),
    clearBlobPolicy: () => dispatch(policyActions.clearBlobPolicy()),
    endorsePermission: () => dispatch(policyActions.hasPermission('Non-Financial Endorsement of Certificates')),
    cancelPermission: () => dispatch(policyActions.hasPermission('Cancel Certificate')),
    cancelInEffectPermission: () => dispatch(policyActions.hasPermission('Cancellation (In-Effect Certificate)')),
    cancelNotInEffectPermission: () => dispatch(policyActions.hasPermission('Cancellation (Not In Effect Certificate)')),
    reissuePermission: () => dispatch(policyActions.hasPermission('Reissue Certificate')),
    clearPolicyDetail: () => dispatch(policyActions.clearPolicyDetail()),
    getStates: (country, param) => dispatch(policyActions.getStates(country, param)),
    notify: (message, type) => dispatch(Notifications.show({ message }, type)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyDetail);
