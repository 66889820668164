//other server keys will be updated
let token = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')).token : ''

const url = 'https://demo-portal.coverplus.io/services/tds';
//const url = 'http://1d734b42.ngrok.io';
const quotationUrl = 'https://demo-portal.coverplus.io/services/quote';
const policyUrl = 'https://demo-portal.coverplus.io/services/policy';
const productsUrl = 'https://demo-portal.coverplus.io/services/rate';
const auditUrl = 'https://demo-portal.coverplus.io/services/audit';
export const env = {
  apiURL: url,
  apiVersion: `${url}`,
  quotationUrl: quotationUrl,
  policyUrl,
  productsUrl,
  auditUrl,
  token
};

