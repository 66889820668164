import type from "history";
import React from 'react';
import {Button} from "reactstrap";
import {Header} from "../../@xc-core/components/core";
import bannerImg from "../../images/bannerGraphicImages/get-quote.png";

interface IProps{
    history:type.History
}

const ThankYouPage =(props:IProps)=>{
    const {history} = props;

    return (
        <>
            <Header
                title='Bulk Cancellation'
                bannerGraphic={bannerImg}
                withBorderBottom
            />
            <div className={'text-center'} style={{marginTop:'4rem'}}>
                <h1 className={'custom-text-primary text-dark'}>Thank You</h1>
                <p className={'mt-3'}>Bulk Cancellation has successful</p>
                <Button
                    className={'btn-primary mt-3'}
                    onClick={()=>history.push('/policies')}
                    style={{
                        borderRadius:5
                    }}
                    size={'sm'}
                >
                    Go to Certificates
                </Button>
            </div>
        </>
    )
}

export default ThankYouPage;