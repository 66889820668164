import {modelId} from "../../Products/shared/utils/modelUtil";

const mainInsuredBasedPath='properties.Main Insured';
const insuredDetailBasePath='properties.Insured Property';
const beneficiariesDetailsBasePath='properties.beneficiaries'

const model =(type:'Individual' | 'Company')=> {
    const getMandatoryIndividualField=(basePath:string)=>{
        return  type === 'Individual' ?[
            {
                id: modelId(basePath, "Individual ID Type"),
                name: "ID Type",
                config:{}
            },
            {
                id: modelId(basePath, "Individual ID Number"),
                name: "ID Number",
                config:{}
            }
        ]:null
    }

    const getMandatoryCompanyField=(basePath:string)=>{
        return type === 'Company' ? [{
            id: modelId(basePath, "Company VATIN"),
            name: "VAT TIN",
            config:{}
        }] : null
    }

    const getIndividualOrCompanyDetail=(basePath:string)=>{
        return [
            { id: modelId(basePath,`${type} Name`), name: `${type === 'Individual' ? 'Full' : 'Company'} Name`, config: {} },
            ...(getMandatoryCompanyField(basePath) || []),
            { id: modelId(basePath,`${type} Business Occupation`), name: `Business Occupation`, config: {} },
            ...(getMandatoryIndividualField(basePath) || [])
        ]
    }

    const getContactDetails=(basePath:string)=>{
        return [
            { id: modelId(basePath,`${type} Address`), name: `Address`, config: {} },
            { id: modelId(basePath,`${type} Country`), name: 'Country', config: {} },
            { id: modelId(basePath,`${type} City`), name: 'City', config: {} },
            { id: modelId(basePath,`${type} State`), name: 'State', config: {} },
            { id: modelId(basePath,`${type} Postcode`), name: 'Postcode', config: {} },
            { id: modelId(basePath,`${type} Email Address`), name: 'Email Address', config: {} },
            { id: modelId(basePath,`${type} Mobile Number`), name: 'Mobile Number', config: {} },
        ]
    }

   return {
       body: {
           items: {
               main: {
                   id: 'properties.Main Insured',
                   items: getIndividualOrCompanyDetail(mainInsuredBasedPath),
                   config: {},
               },
               contactDetails:{
                   id:'properties.Main Insured',
                   items: getContactDetails(mainInsuredBasedPath),
                   config:{}
               },
               insuredPropertyDetails:{
                   id:'properties.Insured Property',
                   items: [
                       { id: modelId(insuredDetailBasePath,`Registration No`), name: `Registration No`, config: {} },
                       { id: modelId(insuredDetailBasePath,`Make/Model`), name: 'Make/Model', config: {} },
                       { id: modelId(insuredDetailBasePath,`Chassis No/Engine No`), name: 'Chassis No./Engine No.', config: {} },
                       { id: modelId(insuredDetailBasePath,`Number of Seats`), name: 'Number of Seats', config: {} },
                       { id: modelId(insuredDetailBasePath,`Year of Manufacture`), name: 'Year of Manufacture', config: {} },

                   ],
                   config:{}
               },
               beneficiaries: {
                   id: 'properties.beneficiaries',
                   items: [
                       ...getIndividualOrCompanyDetail(beneficiariesDetailsBasePath),
                       ...getContactDetails(beneficiariesDetailsBasePath)
                   ],
                   config: {},
               },
           },
           config: {},
       },
   }
};

export default model;
