import React, {useState,useEffect} from 'react';
import {Button, Input} from "reactstrap";
import {actions} from "../../@redux";
import type from 'history'
import {Header, Loader,Table} from "../../@xc-core/components/core";
import bannerImg from "../../images/bannerGraphicImages/get-quote.png";
import {tableTitle} from './constant'
import { useDispatch } from 'react-redux'
interface IProps{
    history:type.History
}

const policyActions = new actions.PolicyActions();

const BulkCancellationPreviewPage=(props:IProps)=>{
    const {history} = props;
    const [previewItem , setPreviewItem] = useState<any>([])
    const [loading , setIsLoading] = useState<boolean>(false);
    const [remarks , setRemarks]= useState<string>('')
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch = useDispatch()

    const bulkCancellationConfirm=async ()=>{
        if(!remarks){
            setIsError(true)
            return;
        }
        setIsLoading(true)
        const res:any = await dispatch(policyActions.confirmBulkCancellation({requestId:previewItem.requestId, remarks}))

        if(res.status === "Confirmed"){
            history.push('/bulkCancellation/complete')
            localStorage.removeItem('policyIds');
            localStorage.removeItem('toBedDeletedPolicies')
            setIsLoading(false)
        }else{
            history.push('/bulkCancellation/failed')
            localStorage.setItem('toBedDeletedPolicies', JSON.stringify({
                ...previewItem,
                remarks
            }))
            setIsLoading(false)
        }
    }

    useEffect(()=>{
        const toBedDeletedItem= localStorage.getItem('toBedDeletedPolicies');
        if(!toBedDeletedItem){
            return history.push('/bulkCancellation');
        }
        setPreviewItem(JSON.parse(toBedDeletedItem));
    },[])

    return(
        <>
            <Header
                title='Bulk Cancellation'
                bannerGraphic={bannerImg}
                withBorderBottom
            />
            {loading && <Loader/>}
            <div
                className="container-fluid px-0"
                style={{
                    margin: '1rem 0 0 4rem'
                }}
            >
                <div className="col-sm-8">
                    <label className='text-regular'>Cancellation Preview</label>
                    <Table
                        titles={tableTitle}
                        records={previewItem?.policies?.map((el:any,index:number)=>{
                            const {properties} = el;
                            return {
                                no: index + 1,
                                refCode: el.refCode,
                                mainInsured:(properties?.['main applicant'] || properties?.['Main Applicant'])?.['Full Name']
                                    || properties?.['Full Name']
                                    || properties?.['Main Insured']?.['Individual Name']
                                    || properties?.['Main Insured']?.['Company Name']
                                ,
                                premiumPaid:properties?.['Previous Price']?.Total,
                                premiumAfterRefund:parseInt(properties?.Price?.Total).toFixed(2)
                            }
                        }) || []}
                        hasRecords={true}
                        image={bannerImg}
                        onRowClicked={() =>{}}
                        onRowButtonClicked={() =>{}}
                        handleSearchKeyword={() =>{}}
                        searchKeyword={''}
                        handlePageClick={() => {}}
                        handlePageSizeChange={()=>{}}
                        pagination={''}
                        activeSearch={false}
                        style={{
                            padding:0
                        }}
                    />
                    <div className={'mt-4 p-0'}>
                        <div className={'col-6 p-0'}>
                            <label className='mt-2 mb-2 text-regular'>Please provide the reason for cancellation</label>
                            <Input
                                type={'textarea'}
                                id='remarks'
                                name='remarks'
                                placeholder='Enter Remarks'
                                value={remarks}
                                onChange={(e) => {
                                    if(isError){
                                        setIsError(false)
                                    }
                                    setRemarks(e.target.value)
                                }}
                                autoFocus
                            />
                            {isError && <span className={'text-danger-step'}>Required</span>}
                        </div>
                    </div>
                    <div className={'col-12 p-0'} style={{textAlign:'right', alignSelf:'flex-end'}}>
                            <span className={'col-2 m-1'}
                                  onClick={() => history.push('/bulkCancellation')}
                            >
                                <span className="customBackbuttonname" style={{color:'#979797',cursor:"pointer"}}>Back</span>
                            </span>
                        <Button
                            className={'btn-primary col-2 m-1'}
                            onClick={bulkCancellationConfirm}
                            style={{
                                borderRadius:5
                            }}
                            size={'sm'}
                            disabled={isError || loading}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default BulkCancellationPreviewPage;