import React, { useEffect, useState } from 'react';
import { quotationActions } from 'Products/shared/quotationListing/actions';
import { connect } from 'react-redux';
import FlashMessage from '_components/FlashMessage';
import TravelInsuranceQuotationDetail from 'Products/TravelInsurance';
import CashForHospitalizationQuotation from 'Products/CashForHospitalization/quotation';
import AlfalahTravelInsuranceQuotationDetail from 'Products/AlfalahTravelInsurance';
import { HreTravelInsuranceQuotationDetail } from 'Products/HreTravelInsurance';
import WalletInsuranceQuotation from 'Products/WalletInsurance/quotation';
import CBTravelQuotationDetail from 'Products/CBTravelInsurance/quotationDetail';
import CBFlexiPaDetail from 'Products/CBFlexiPa/quotationDetail';
import HgiQuotationDetail from 'Products/hgiInsurance/quotationDetail';
import ICareQuotationDetail from '@products/gbsn/i-care/quotation-detail';
import IMedQuotationDetail from '@products/gbsn/i-med/quotation-detail';
import IProtectQuotationDetail from '@products/gbsn/i-protect/quotation-detail';
import MedicalInsuranceQuotationDetail from 'Products/MedicalInsurance/quotationDetail';
import MotorInsuranceQuotationDetail from 'Products/MotorInsurance/quotationDetail';
import PersonalAccidentQuoteDetail from 'Products/PersonalAccident/quotationDetail';
import MyMotorInsuranceQuotationDetail from 'Products/MyMotorInsurance/quotationDetail';
import ABCTravelInsuranceQuotationDetail from 'Products/ABCTravelInsurance/quotationDetail';
import AutoInsuranceQuotationDetail from '../../AutoInsurance/Quotation/quotationDetail';
import config from '_config';
import { quotationModel } from 'models';


const QuotationDetailsStack = (props) => {
  const [productCode, changeProductCode] = useState(null);
  useEffect(() => {
    const { match: { params } } = props;
    props.clearQuotationDetail();
    props.getDetail(params.id);
  }, []);

  useEffect(() => {
    if (props.quotation) {
      changeProductCode(Number(props.quotation.productCode));
    }
  }, [props.quotation]);
  if (productCode) {
    switch (productCode) {
      case config.products.travelInsurance.id:
        return <TravelInsuranceQuotationDetail {...props} />;
      case config.products.hreTravelInsurance.id:
        return <HreTravelInsuranceQuotationDetail {...props} />;
      case config.products.walletInsurance.id:
        return <WalletInsuranceQuotation {...props} />;
      case config.products.cashForHospitalization.id:
        return <CashForHospitalizationQuotation {...props} />;
      case config.products.alfalahTravelInsurance.id:
        return <AlfalahTravelInsuranceQuotationDetail {...props} />;
      case config.products.hgiInsurance.id:
        return <HgiQuotationDetail {...props} history={props.history} />;
      case config.products.cbTravelInsurance.id:
        return <CBTravelQuotationDetail {...props} model={quotationModel.travelCB} />;
      case config.products.flexiPa.id:
        return <CBFlexiPaDetail {...props} model={quotationModel.flexiPaCB} />;
      case config.products.gbsnICare.id:
        return <ICareQuotationDetail history={props.history} />;
      case config.products.gbsnIMed.id:
        return <IMedQuotationDetail history={props.history} />;
      case config.products.gbsnIProtect.id:
        return <IProtectQuotationDetail history={props.history} />;
      case config.products.medicalInsurance.id:
        return <MedicalInsuranceQuotationDetail history={props.history} {...props} model={quotationModel.medicalInsurance}/>;
      case config.products.motorInsurance.id:
        return <MotorInsuranceQuotationDetail history={props.history} {...props} model={quotationModel.motorInsurance}/>;
      case config.products.personalAccident.id:
        return <PersonalAccidentQuoteDetail history={props.history} {...props} model={quotationModel.personalAccident} />;
      case config.products.myMotorInsurance.id:
        return <MyMotorInsuranceQuotationDetail history={props.history} {...props} model={quotationModel.myMotorInsurance}/>;
      case config.products.abcTravelInsurance.id:
        return <ABCTravelInsuranceQuotationDetail {...props} model={quotationModel.travelCB} />;
      case config.products.autoInsurance.id:
        return <AutoInsuranceQuotationDetail history={props.history} {...props}/>
      default:
        return <FlashMessage flashMessage='unknown product code' />;
    }
  }
  return (
    <div className='loader' />
  );
};


function mapStateToProps(state) {
  const { quotation } = state.quotationManagement;
  return { quotation };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetail: (quotationId) => dispatch(quotationActions.getDetail(quotationId)),
    clearQuotationDetail: () => dispatch(quotationActions.clearQuotationDetail()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuotationDetailsStack);
