const model = {
    header: {
        productName: { id: 'productName', name: '', config: {} },
        plan: { id: 'properties.Coverage Type', name: '', config: {} },
        total: { id: 'properties.Price.Total', name: 'Total Price',ext: ' / តម្លៃសរុប', config: {} },
        config: {},
    },
    body: {
        items: [
            {
                id: 0,
                title: { id: 'Price Breakdown', name: 'Price Breakdown', ext: ' / តម្លៃលម្អិត' },
                items: [
                    { id: 'properties.Price.Main Policy', name: 'Gross Premium', ext: ' / បុព្វលាភដុល', config: { currency: true } },
                    { id: 'properties.Price.Admin Fee', name: 'Admin Fee', ext: ' / សេវារដ្ឋបាល', config: { currency: true } },
                ],
                config: {},
            },
            {
                id: 'otherDetails',
                title: { id: 'Other Details', name: 'Other Details', ext:' / ព័ត៌មានលម្អិតផ្សេងទៀត' },
                items: [
                    { id: 'properties.Product Type', name: 'Product Type',ext:' / ប្រភេទផលិតផល', config: {} },
                    { id: 'properties.Sum Insured', name: 'Sum Insured',ext:' / ទឹកប្រាក់ធានា', config: {currency: true} },
                    { id: 'properties.Coverage Type', name: 'Coverage Type', ext: '/ ប្រភេទហានិភ័យដែលត្រូវបានធានារ៉ាប់រង', config: {} },
                    { id: 'properties.Cubic Capacity', name: 'Cubic Capacity',ext:' / ទំហំស៊ីឡាំង', config: {} },
                ],
                config: {},
            }
        ],
    },
};

export default model;
