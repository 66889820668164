import moment from 'moment';

/**
 * Days to subscribe should be either 0 or 364
 * @param yearsFromNow (this is the number of years to subtract from today)
 * @param monthsToSubtract
 * @param daysToSubtract (this is the number of days before getting
 * into another category ex: from adult to senior)
 * @return Moment string date
 */
const getDateByYearMonthDay = (yearsFromNow = 0, monthsToSubtract = 0, daysToSubtract = 0, defaultDate = false) => {
  const date = (defaultDate) ? defaultDate : new Date();
  return moment(date)
    .subtract(`${yearsFromNow}`, 'years')
    .subtract(`${monthsToSubtract}`, 'months')
    .subtract(`${daysToSubtract}`, 'days')._d;
};


/* since we cannot calculate accurate DOB based on age, we add 364 days to get an estimate of
boundary and validate data when user selects the actual DOB  */

/** A Child’s valid age should be 0 to 17 years old in days. */
/** (0 year 0 month 0 day) to  (17 years 364 days) */
const childrenRange = {
  maxDate: getDateByYearMonthDay(),
  minDate: getDateByYearMonthDay(17, 0, 364),
  maxAge: 17,
  minAge: 0,
};

/** An Adult’s valid age should be 18 to 65 years old in days. */
/** (18 years 0 month 0 day) to (65 years 364 days) */
const adultRange = {
  maxDate: getDateByYearMonthDay(18),
  minDate: getDateByYearMonthDay(65, 0, 364),
  maxAge: 65,
  minAge: 18,
};

/** A Senior Citizen’s valid age should be 66 to 80 years old in days. */
/** (66 years 0 month 0 day) to (80 years 364 days) */
const senior6680Range = {
  maxDate: getDateByYearMonthDay(66),
  minDate: getDateByYearMonthDay(80, 0, 364),
  maxAge: 80,
  minAge: 66,
};

/** A Senior Citizen’s 66-75 valid age should be 66 to 75 years old in days. */
/** (66 years 0 month 0 day) to (75 years 364 days) */
const senior6675Range = {
  maxDate: getDateByYearMonthDay(66),
  minDate: getDateByYearMonthDay(75, 0, 364),
  maxAge: 75,
  minAge: 66,
};

/** A Senior Citizen’s 76-80 valid age should be 76 to 80 years old in days. */
/** (76 years 0 month 0 day) to (80 years 364 days) */
const senior7680Range = {
  maxDate: getDateByYearMonthDay(76),
  minDate: getDateByYearMonthDay(80, 0, 364),
  maxAge: 80,
  minAge: 76,
};

/** A Senior Citizen’s 81-85 valid age should be 81 to 85 years old in days. */
/** (81 years 0 month 0 day) to (85 years 364 days) */
const senior8185Range = {
  maxDate: getDateByYearMonthDay(81),
  minDate: getDateByYearMonthDay(85, 0, 364),
  maxAge: 85,
  minAge: 81,
};

/** Cash - An Adult’s valid age should be 18 to 64 years old in days. */
/** (18 years 0 month 0 day) to (64 years and 364 days) */
const cashAdult1864DobRange = {
  max: getDateByYearMonthDay(18,0,1),
  min: getDateByYearMonthDay(65,0,364),
  // maxAge: 64,
  // minAge: 18,
};

export {
  childrenRange,
  adultRange,
  senior6680Range,
  senior6675Range,
  senior7680Range,
  senior8185Range,
  cashAdult1864DobRange,
  getDateByYearMonthDay
};
