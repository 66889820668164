import React, {useEffect, useState} from 'react';
import { History } from 'history';
import {Details} from '../../../@containers/quotation'
import AutoInsurance from 'models/quotation/autoInsurance';
import {sidebarModel} from 'models'
import lib from "../../../utils/lib";

interface IProps{
    history:History;
    backPath:string;
    quotation: any;
    sidebarModel:any;
    editQuotation:()=>void;
}

const QuotationDetail =(props:IProps)=>{
    const {quotation} = props
    const isBelongToCompany = quotation?.properties?.['Main Insured']?.['Belongs to Company'] === 'Yes'
    const model = AutoInsurance(isBelongToCompany ? 'Company' :"Individual").body;
    let premiumSidebar = {...sidebarModel.quotation.autoInsurance};
    if(quotation?.properties?.['Coverage Type'] === "Third Party Liability"){
        premiumSidebar= lib.removeSidebarBodyItem(premiumSidebar,[
            {
                parentKey:'Other Details',
                removeItem: ['properties.Sum Insured']
            }
        ])
    }
    return (
        <>
            <Details
                {...props}
                type={"quotation"}
                data={quotation}
                sidebarModel={premiumSidebar}
                components={[
                    {
                        title:`${isBelongToCompany ? 'Company' : 'Individual'} Details`,
                        model:model.items.main,
                        type:'primary'
                    },
                    {
                        title:'Contact Details',
                        model:model.items.contactDetails,
                        type:'primary'
                    },
                    {
                        title:'Property Details',
                        model:model.items.insuredPropertyDetails,
                        type:'primary'
                    },
                    {
                        title:'Beneficiaries',
                        model:model.items.beneficiaries,
                        type:'secondary'
                    }
                ]}
                productIcon={'icon-motorcar'}
            />
        </>
    )
}

export default QuotationDetail;