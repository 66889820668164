import React, {useEffect} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import {SidebarCard} from "../../../@xc-core/components";
import {modelId} from "../../../Products/shared/utils/modelUtil";
import lib from "../../../utils/lib";

const InsuredDetails =(props:ILoadableComponentProps)=>{
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            initValues,
            sidebar,
            isKhmer,
            productIcon,
            db
        },
    } = props;
    const basicContactKey =["Name","VATIN","Business Occupation","ID Type",'ID Number']
    const isBelongToCompany = item.properties?.['Main Insured']?.['Belongs to Company'] === 'Yes'
    const insuredDetailModel:IField[]= isBelongToCompany ? model.companyInsuredFields.fields :model.individualInsuredFields.fields

    useEffect(()=>{
        if(!item.properties?.['Main Insured']?.['Belongs to Company']){
            initValues([
                {
                    id:'properties.Main Insured.Belongs to Company',
                    value:'No'
                },
                {
                    id:`properties.Main Insured.${isBelongToCompany ? 'Company' : 'Individual'} Country`,
                    value:"Cambodia"
                },
                {
                    id:'properties.Insured Property',
                    value:{}
                }
            ])
        }
    },[])

    const getPartOfTheModel=(modelType:'basicInfo'|'contactDetail')=>{
        return insuredDetailModel.filter((el)=>{
            const val = basicContactKey.some((el2)=>
                modelId('properties.Main Insured' ,`${isBelongToCompany ? 'Company' : 'Individual'} ${el2}`) === el.id);
            return modelType === "basicInfo" ? val : !val
        });
    }

    return (
        <Row>
            <Col md={'8'}>
                <h5 className='sub-title'>{lib.withKhmerText(model.title,db)}</h5>
                <Col className={'pl-0'} md='12'>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={model.mainInsuredFields.fields}
                    />
                </Col>
                <Col className={'pl-0'} md='12'>
                    <p className='custom-text-primary'>{lib.withKhmerText(isBelongToCompany ? 'Company Details' : 'Individual Details',db)}</p>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={getPartOfTheModel('basicInfo')}
                    />
                </Col>
                <Col className={'pl-0'} md='12'>
                    <p className='custom-text-primary'>{lib.withKhmerText("Contact Details",db)}</p>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={getPartOfTheModel('contactDetail')}
                    />
                </Col>
                <Col className={'pl-0'} md='12'>
                    <p className='custom-text-primary'>{lib.withKhmerText('Property Details',db)}</p>
                    <RenderFields
                        onChange={(pairs: IPair[]) => {
                            onChange('onChange', {pairs})
                        }}
                        collections={collections}
                        item={item}
                        ext={false}
                        validationErrors={validationErrors}
                        fields={model.insuredDetailFields.fields}
                    />
                </Col>
            </Col>
            <Col md='4'>
                <SidebarCard.Quotation
                    customIconProduct={productIcon}
                    item={item}
                    model={sidebar}
                    ext={isKhmer}
                />
            </Col>

        </Row>
    )
}

export default InsuredDetails;