import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import SimpleReactValidator from 'simple-react-validator';
import BasicInfoForm from "../containers/BasicInfoForm";
import PersonalInfo from "../containers/PersonalInfo";
import { SidebarCard } from '@xc-core/components';
import { sidebarModel } from 'models';
import config from "_config";
import { utils } from "utils";
import {
  getValidationDates,
  mainInsuredType,
  getIndicesForTitles,
  updateIndicesByType,
} from '../../alfalahUtils';
class CustomerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator(utils.validations.alfalahValidationObject); //validationObject);
    this.state = this.props.curentThis.state;
  }

  async componentDidMount() {
    const {curentThis} = this.props;
    const {
      props: {
        item: {properties},
        history: {location: {pathname}},
      },
    } = curentThis;
    const country = properties.Country || config.products.alfalahTravelInsurance.defaultValues.country;
    await this.props.getStates(country, '?collection=state_alf');// item.productCode);
    if (pathname.includes("editQuotation")) {
    //   curentThis.createOtherInsuredObj();
    //   curentThis.createBeneficiaryObj();
    // } else if (!properties["Other Insured"]) {
    //   curentThis.setState(prevState => ({
    //     properties: {...prevState.properties, "Other Insured": OtherInsured}
    //   }));
      if (curentThis.state.OtherInsured && !curentThis.state.OtherInsured.length) {
        curentThis.createOtherInsuredObj();
      }
    }
    this.props.onRef(this);
  }

  getAlert() {
    const { curentThis } = this.props;
    if (this.validator.allValid()) {
      // this.props.curentThis.setState({disabled:false});
      return true;
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      curentThis.setInvalidForm(true);
      // this.props.curentThis.setState({disabled:true});
      return false;
    }
  }

  validateForm = async () => {
    const { curentThis } = this.props;
    return this.validator.allValid() && curentThis.setInvalidForm(false);
  }


  render() {
    this.validator.purgeFields();

    const {curentThis} = this.props;
    const {props: {item ,item: {properties, productName}}} = curentThis;
    const {OtherInsured} = this.props.curentThis.state;
    const curentStep = this;
    const indices = getIndicesForTitles(properties);
    const mainInsuredDateOfBirth = getValidationDates(mainInsuredType(indices));
    const mainInsuredTypeTitle = mainInsuredType(indices);
    return (
      <Row>
        <Col md="8">
          <h5 className="sub-title">Please enter the insured person(s) details</h5>
          <p className="custom-text-primary">
            {`${mainInsuredTypeTitle} [1] - Main Insured Details`}
          </p>
          <Row>
            <BasicInfoForm
              updatedVal={curentThis.state.properties}
              handleInputChange={async (value) => {
                await curentThis.handleInputChange(value);
                await this.validateForm();
              }}
              index="base"
              startDate={curentThis.state.properties["DOB"]}
              insurerType={mainInsuredTypeTitle}
              openToDateOfBirth={mainInsuredDateOfBirth.maxDateOfBirth}
              maxDateOfBirth={mainInsuredDateOfBirth.maxDateOfBirth}
              minDateOfBirth={mainInsuredDateOfBirth.minDateOfBirth}
              minAge={mainInsuredDateOfBirth.minAge}
              maxAge={mainInsuredDateOfBirth.maxAge}
              onChange={async (value) => {
                await curentThis.handleDOB(value);
                return this.validateForm();
              }}
              collections={curentThis.props.collections}
              validator={this.validator}
              beneficiary={false}
              curentThis={curentThis}
              handleDobInputDateChange={async (value) => {
                await curentThis.handleDOB(value);
                return this.validateForm();
              }}
              handleIssuanceDate={async (value) => {
                await curentThis.handleIssuanceDate(value);
                return this.validateForm();
              }}
              autoFocus={true}
              validateForm={this.validateForm}
            />
          </Row>
          <p className="mt-4"> Contact Details </p>
          <Row>
            <PersonalInfo
              updatedVal={curentThis.state.properties}
              handleInputChange={async (value) => {
                await curentThis.handleInputChange(value);
                return this.validateForm();
              }}
              index=""
              startDate={curentThis.state.properties["DOB"]}
              onChange={async (value) => {
                await curentThis.handleDOB(value);
                return this.validateForm();
              }}
              collections={curentThis.props.collections}
              validator={this.validator}
              curentThis={curentThis}
            />
          </Row>
          {OtherInsured.length >= 1 &&
          OtherInsured.map((data, index) => {
            let dateOfBirth = getValidationDates(data.Type);
            return (
              <Fragment key={index}>
                <hr/>
                <p className="custom-text-primary">{`${data.Type} [${updateIndicesByType(indices, data.Type)}]`}</p>
                <Row>
                  <BasicInfoForm
                    updatedVal={curentThis.state.OtherInsured[index]}
                    // handleInputChange={curentThis.updateOtherInsured.bind(
                    //   this,
                    //   data.id
                    // )}
                    handleInputChange={async (event) => {
                      await curentThis.updateOtherInsured(data.id, event);
                      this.validator.allValid() && await  curentThis.setInvalidForm(false);
                    }}
                    index={index}
                    startDate={curentThis.state.OtherInsured[index]["DOB"]}
                    insurerType={data.Type}
                    openToDateOfBirth={dateOfBirth.maxDateOfBirth}
                    maxDateOfBirth={dateOfBirth.maxDateOfBirth}
                    minDateOfBirth={dateOfBirth.minDateOfBirth}
                    maxAge={dateOfBirth.maxAge}
                    minAge={dateOfBirth.minAge}
                    // onChange={curentThis.updateOtherInsured.bind(
                    //   this,
                    //   data.id
                    // )}
                    onChange={async (event) => {
                      await curentThis.updateOtherInsured(data.id, event);
                      this.validator.allValid() && await curentThis.setInvalidForm(false);
                    }}
                    validator={this.validator}
                    collections={curentThis.props.collections}
                    beneficiary={false}
                    curentThis={curentThis}
                    curentStep={curentStep}
                    curentID={data.id}
                    handleDobInputDateChange = {async (date) => {
                      var validInput = await this.validator.check({
                        value: date,
                        validationParams:
                        {
                          maxDateOfBirth: dateOfBirth.maxDateOfBirth,
                          minDateOfBirth: dateOfBirth.minDateOfBirth,
                          minAge: dateOfBirth.minAge,
                          maxAge: dateOfBirth.maxAge,
                        }
                      }, 'validate_age_boundary')
                      if (validInput) {
                        await curentThis.updateOtherInsuredDate(data.id, date, 'dob');
                        this.validator.allValid() && await curentThis.setInvalidForm(false);
                      } else {
                        this.validator.allValid() && curentThis.invalidForm && await curentThis.setInvalidForm(true); 
                      }
                    }}
                    handleIssuanceDate={async (date) => {
                      await curentThis.updateOtherInsuredDate(data.id, date, 'issuanceDate');
                      this.validator.allValid() && await curentThis.setInvalidForm(false);
                    }}
                    autoFocus={false}
                    validateForm={this.validateForm}
                  />
                </Row>
              </Fragment>
            );
          })}
        </Col>
        <Col md="4">
          <SidebarCard.Quotation
            properties={properties}
            data={properties}
            productName={productName}
            model={sidebarModel.quotation.travelAlfalah.getModelWithFilteredSections(properties)}
            item={item}
          />
        </Col>
      </Row>
    );
  }
}

export default CustomerDetails;
