import React, {ReactNode, useState,useEffect} from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import {objLib} from '../../../lib'
import './index.scss'

type pillStyle='red-pill' | 'white-pill';

interface IPillProps{
    label?:string;
    pillStyle?:pillStyle;
    style?:React.CSSProperties;
    children?:ReactNode
}

interface IPill{
   id:string,text:string,pillStyle?:pillStyle;
}

interface ITagInputProps{
    onChange:(value:string[], duplicateValues:string[])=>void;
    placeholder?:string;
    customPills?:(pill:IPill)=>IPill
    errorMessages?:string[];
    duplicateErrorMessage?:string;
    values:string[]
}

const Pills=(props:IPillProps)=>{
    const {style,pillStyle}= props;
    return (
        <span className={`tag-pill ${pillStyle}`} style={style}>
            {props.children}
        </span>
    )
}

const TagInput =(props:ITagInputProps)=>{
    const {customPills,placeholder,onChange , errorMessages ,duplicateErrorMessage,values} = props;
    const [pills, setPills] = useState<IPill[]>([])
    const [isDuplicate , setIsDuplicate] = useState<boolean>(false)

    useEffect(()=>{
        if(values.length !== 0){
            const joinedVal= values.join(',');
            pillHandler({
                id:joinedVal,
                text:joinedVal
            })
        }
    },[])

    const pillHandler = (tag?:IPill , type?:'remove' , toBeDeletedIndex?:number) => {
        const items:IPill[] | undefined = type === 'remove' ?
            pills.filter((tag, index) => index !== toBeDeletedIndex):
            (tag ? [...pills, ...objLib.commaSeparator(tag.text).map((el,index)=>{
                const checkSlash = el.split('/');

                return {
                    id:checkSlash[0],
                    text:el
                }})] : undefined)

        if(!items) return;

        const duplicatesValue= objLib.getDuplicatesValue(items.map((el)=>el.id))
        setIsDuplicate(duplicatesValue.length !== 0)

        const newPillsItems:IPill[] = items.map((item,index)=>{
            if(duplicatesValue.indexOf(item.id) !== -1){
                return {
                    ...item,
                    pillStyle:'red-pill'
                }
            }
            return {
                ...item,
                pillStyle:'white-pill'
            }
        })
        setPills(newPillsItems)
        onChange(newPillsItems.map((el)=>el.text),duplicatesValue)
    };

    return (
        <>
            <div
                className={'tagContainer'}
                onClick={()=>{
                    document.getElementById('tag-input-id')?.focus();
                }}
                onContextMenu={(e)=>{
                    document.getElementById('tag-input-id')?.focus();
                }}
                contentEditable={true}
                suppressContentEditableWarning={true}
            >
                {
                    pills && pills.map((val,index)=>{
                        const pillValue = customPills ? customPills(val) : val
                        const {pillStyle,text} = pillValue
                        return (
                            <Pills
                                key={index}
                                pillStyle={pillStyle}
                            >
                                {text}
                                <span
                                    className={`close ${pillStyle}`}
                                    style={{
                                        marginLeft:5,
                                        opacity:2,
                                    }}
                                    onClick={()=>{
                                        pillHandler(undefined ,'remove' ,index)
                                    }}
                                >
                                &times;
                            </span>
                            </Pills>
                        )
                    })
                }
                <ReactTags
                    id={'tag-input-id'}
                    placeholder={placeholder}
                    tags={pills}
                    handleDelete={i => pillHandler(undefined ,'remove' ,i)}
                    handleAddition={pillHandler}
                    inputFieldPosition="inline"
                    allowAdditionFromPaste={true}
                    classNames={{
                        tag: 'display-none',
                    }}
                    autofocus={true}
                />
            </div>
            {
                (errorMessages || isDuplicate) && (
                    <div className={'text-danger-step'}>
                        <ul style={{padding:'10px 20px', fontSize:'0.9rem'}}>
                            {duplicateErrorMessage && isDuplicate && (<li>{duplicateErrorMessage}</li>)}
                            {errorMessages?.map((errMsg,index)=> <li key={index}>{errMsg}</li>)}
                        </ul>
                    </div>
                )
            }
        </>
    )
}

export default TagInput