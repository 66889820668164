import { dateLib, objLib } from '@xc-core/lib';
import config from "../../../@xc-core/config";

const noOfDaysId = 'properties.No. of Days';
const startDateId = 'properties.Start Date';
const endDateId = 'properties.End Date';
const tripTypeId = 'properties.Trip Type';
const destinationId = 'properties.Destination';

const getEndDateForAnnualType = (startDate) => {
    let endDate = dateLib.addToDate(startDate, 1, 0, 0);
    endDate = dateLib.subtractFromDate(endDate, 0, 0, 1);
    return endDate;
};

const getNoOfDays = (date1, date2) => dateLib.getDiff(date1, date2, 'days') + 1;


function defaultDisabled() {
    return false;
}

function defaultHandler(pair) {
    return [pair];
}

const handleStartEndDate = (pair, item) => {
    const pairsToUpdate = [];
    const prevStartDate = objLib.getValueWithLodash(item, startDateId);
    const prevEndDate = objLib.getValueWithLodash(item, endDateId);
    const tripType = objLib.getValueWithLodash(item, tripTypeId);
    let noOfDays = 0;
    if (pair.id.includes('Start Date') && prevEndDate) {
        if (tripType === 'Annual') {
            const endDate = getEndDateForAnnualType(pair.value);
            pairsToUpdate.push({ id: endDateId, value: endDate });
            noOfDays = getNoOfDays(endDate, pair.value);
        } else noOfDays = getNoOfDays(prevEndDate, pair.value);
    } else if (pair.id.includes('End Date') && prevStartDate) {
        noOfDays = getNoOfDays(pair.value, prevStartDate);
    }
    pairsToUpdate.push({ id: pair.id, value: pair.value });
    pairsToUpdate.push({ id: noOfDaysId, value: noOfDays });
    return pairsToUpdate;
};

const handleTripType = (pair, item) => {
    const { submittedValues } = item;
    const { value, id } = pair;
    let endDate;
    const currentStartDate = objLib.getValueWithLodash(item, startDateId);
    const submittedTripType = submittedValues['Trip Type'];

    if (value === 'Annual') {
        endDate = submittedTripType === value ? submittedValues['End Date'] : getEndDateForAnnualType(currentStartDate);
    } else {
        endDate = submittedTripType === value ? submittedValues['End Date'] : dateLib.addToDate(currentStartDate, 0, 0, 4);
    }

    let destination = '';
    if (value === submittedTripType) {
        destination = submittedValues.Destination;
    }
    const noOfDays = getNoOfDays(currentStartDate, endDate);
    const resultPairs = [
        { id, value },
        { id: endDateId, value: endDate },
        { id: noOfDaysId, value: noOfDays },
        { id: destinationId, value: destination },
    ];
    return resultPairs;
};

const handleCountryOfOrigin = (pair) => [
    pair,
];


const model = {
    header: {
        id: '',
        name: '',
        productName: { id: 'productName', name: '', config: {} },
        plan: { id: 'properties.Plan', name: '', config: {} },
        items: [
            {
                current: { id: 'properties.Price.Total', name: '', config: { currency: true } },
                prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
                config: {},
            },
        ],
    },
    body: {
        items: [
            {
                id: 'Price Breakdown',
                name: 'Price Breakdown',
                items: [
                    {
                        current: { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
                        prev: { id: 'properties.Previous Price.Main Policy', name: 'Gross Premium', config: { currency: true, className: 'cross-line light-gray' } },
                        config: { currency: true },
                    },
                    {
                        current: { id: 'properties.Price.Admin Fee', name: 'Admin Fee', config: { currency: true } },
                        prev: { id: 'properties.Previous Price.Admin Fee', name: 'Admin Fee', config: { currency: true, className: 'cross-line light-gray' } },
                        config: { currency: true },
                    },
                    {
                        current: { id: 'properties.Price.Tax', name: 'Tax', config: { currency: true } },
                        prev: { id: 'properties.Previous Price.Tax', name: 'Tax', config: { currency: true, className: 'cross-line light-gray' } },
                        config: { currency: true },
                    }
                ],
                config: {},
            },
            {
                id: 'Commission Breakdown',
                name: 'Commission Breakdown',
                items: [
                    {
                        current: { id: 'properties.Commission Amount', name: 'Commission Amount', config: { currency: true } },
                        prev: { id: 'properties.Previous Commission', name: 'Commission Amount', config: { currency: true, className: 'cross-line light-gray' } },
                        config: { currency: true },
                    },
                ],
                config: {},
            },
            {
                id: 'Other Details',
                name: 'Policy Details',
                items: [
                    {
                        current: {
                            id: 'properties.Plan',
                            name: 'Plan',
                            type: 'Select',
                            collectionId: 'plans',
                            config: {},
                            disabled: defaultDisabled,
                            handler: defaultHandler,
                        },
                        prev: { config: {} },
                        config: {},
                    },
                    {
                        current: {
                            id: 'properties.Trip Type',
                            name: 'Trip Type',
                            type: 'Select',
                            collectionId: 'tripTypes',
                            config: {},
                            disabled: defaultDisabled,
                            handler: handleTripType,
                        },
                        prev: { config: {} },
                        config: {},
                    },
                    {
                        current: {
                            id: 'properties.Country of Origin',
                            name: 'Country of Origin',
                            type: 'Select',
                            collectionId: 'countriesOfOrigin',
                            config: {},
                            disabled: defaultDisabled,
                            handler: handleCountryOfOrigin,
                        },
                        prev: { config: {} },
                        config: {},
                    },
                    {
                        current: {
                            id: 'properties.Destination',
                            name: 'Destination',
                            type: 'Select',
                            collectionId: 'destinations',
                            config: {},
                            disabled: defaultDisabled,
                            handler: defaultHandler,
                        },
                        prev: { config: {} },
                        config: {},
                    },
                    {
                        current: {
                            id: 'properties.Start Date',
                            name: 'Start Date',
                            type: 'DatePicker',
                            placeholder: 'Set Start date',
                            start: 'properties.Start Date',
                            end: 'properties.End Date',
                            min: dateLib.getCurrentDate(config.dateMomentFormat),
                            config: {},
                            disabled: defaultDisabled,
                            handler: handleStartEndDate,
                        },
                        prev: { config: {} },
                        config: {},
                    },
                    {
                        current: {
                            id: 'properties.End Date',
                            name: 'End Date',
                            type: 'DatePicker',
                            placeholder: 'Set End date',
                            start: 'properties.Start Date',
                            end: 'properties.End Date',
                            min: dateLib.getCurrentDate(config.dateMomentFormat),
                            config: {},
                            disabled: defaultDisabled,
                            handler: handleStartEndDate,
                        },
                        prev: { config: {} },
                        config: {},
                    },
                    {
                        current: {
                            id: 'properties.Insured Type',
                            name: 'Insured Type',
                            type: 'Select',
                            collectionId: 'insuredType',
                            config: {},
                            label: 'Insured Type',
                            style: { col: '12' },
                            disabled: defaultDisabled,
                            handler: defaultHandler,
                        },
                        prev: { config: {} },
                        config: {},
                    },
                ],
                config: { isEditable: true },
            },
        ],
    },
};

export default model;
