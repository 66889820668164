function defaultDisabled() {
  return false;
}
function defaultHandler(pair) {
  return [pair];
}
function handleInsuredType(pair){
  const { value } = pair;
  const updatedPairs = [pair];
  if (value === 'Individual') {
    updatedPairs.push({ id: 'properties.Spouse Occupation', value: 'None' });
    updatedPairs.push({ id: 'properties.Spouse Occupation Class', value: 'None' });
  }
  return updatedPairs
}

const model = {
  header: {
    productName: { id: 'productName', name: '', config: {} },
    plan: { id: 'properties.Plan', name: '', config: {} },
    items: [
      {
        current: { id: 'properties.Price.Total', name: 'Total Price', config: { currency: true } },
        prev: { id: 'properties.Previous Price.Total', name: '', config: { currency: true, className: 'cross-line gray bold' } },
        config: {},
      },
    ],
  },
  body: {
    items: [
      {
        id: 'Price Breakdown',
        name: 'Price Breakdown',
        items: [
          {
            current: { id: 'properties.Price.Main Policy', name: 'Gross Premium', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Main Policy', name: 'Gross Premium', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.Admin Fee', name: 'Admin Fee', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Admin Fee', name: 'Admin Fee', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
          {
            current: { id: 'properties.Price.Tax', name: 'Tax', config: { currency: true } },
            prev: { id: 'properties.Previous Price.Tax', name: 'Tax', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
      {
        id: 'Commission Breakdown',
        name: 'Commission Breakdown',
        items: [
          {
            current: { id: 'properties.Commission Amount', name: 'Commission Amount', config: { currency: true } },
            prev: { id: 'properties.Previous Commission', name: 'Commission Amount', config: { currency: true, className: 'cross-line light-gray' } },
            config: { currency: true },
          },
        ],
        config: {},
      },
      {
        id: 'Other Details',
        name: 'Other Details',
        items: [
          { current: { id: 'properties.Plan', name: 'Plan',type: 'Select', collectionId: 'plans', config: {} ,disabled: defaultDisabled, handler: defaultHandler}, prev: { config: {} }, config: {} },
        ],
        config: { isEditable: true },
      },
      {
        id: '',
        name: '',
        items: [
          { current: { id: 'properties.Insured Type', name: 'Insured Type',type: 'Select', collectionId: 'insuredType', config: {} ,disabled: defaultDisabled, handler: handleInsuredType}, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Individual Occupation', name: 'Individual Occupation',type: 'Select', collectionId: 'occupation', config: {} ,disabled: defaultDisabled, handler: defaultHandler}, prev: { config: {} }, config: {} },
          { current: { id: 'properties.Spouse Occupation', name: 'Spouse Occupation',type: 'Select', collectionId: 'spouseOccupation', config: {} ,disabled: defaultDisabled, handler: defaultHandler}, prev: { config: {} }, config: {} },
        ],
        config: { isEditable: false },
      },
    ],
  },
};

export default model;
