import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { CommonInputTypes } from '_components';
import Moment from 'moment';

const minAgeDays = 29;
const minAgeYrs = 18;
const maxAgeYrs = 65;

const ValidationMessages = ({validator, disabled, value='', inputName, validationRules}) => {
  return (
    <div>
      {
        validator && !disabled
        && validator.message(inputName, value,
          validationRules ? `${validationRules}|customRequired` : 'customRequired')
      }
    </div>
  );
};

const PolicyEndorsement = (props) => {
  const {
    properties,
    collections,
    handleChange,
    handleNumberFormatFields,
    validator,
    beneficiaryValidator,
  } = props;
  const { beneficiaries, 'main applicant': mainApplication } = properties;
  const isBeneficiary = true;

  const getMinDOB = () => {
    return Moment(new Date())
    .subtract(`${minAgeYrs}`, 'years')
    .subtract(`${0}`, 'months')
    .subtract(`${0}`, 'days')._d;
  };

  const getMaxDOB = () => {
    return Moment(new Date())
    .subtract(`${maxAgeYrs}`, 'years')
    .subtract(`${0}`, 'months')
    .subtract(`${365}`, 'days')._d;
  };

  return (
    <Row>
      <Col sm={12}>
        <h4 className='beneficiary-header my-3 p-3'>Main Applicant</h4>
        <Row className='wordBreak'>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Customer Details</div>
            <Row>
              <Col className='pt-2 summary-label' sm={6}>Full Name</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Full Name'
                    name='Full Name'
                    placeholder='Enter full name'
                    value={mainApplication['Full Name']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='full_name_valid_characters'
                    value={mainApplication['Full Name']}
                    inputName='Full Name'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Nationality</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='Nationality'
                    name='Nationality'
                    value={mainApplication['Nationality']}
                    onChange={(e) => handleChange(e)}
                >
                  {
                    collections.nationalities.map(nationality => {
                      return (<option key={nationality.id}>{nationality.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Type</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='ID Type'
                    name='ID Type'
                    value={mainApplication['ID Type']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                >
                  {
                    collections.idTypes.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='ID Number'
                    name='ID Number'
                    placeholder='Enter ID number'
                    value={mainApplication['ID Number']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={mainApplication['ID Number']}
                    inputName='ID Number'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Date of Birth</Col>
              <Col className='pt-2 summary-label-2-other font-size-small' sm={6}>
                <CommonInputTypes
                  id='DOB'
                  dateStyle={{ marginBottom: 0 }}
                  validator={validator}
                  validationRules='date_of_birth_boundary'
                  validationParams={{ maxAgeYrs, minAgeYrs }}
                  group={false}
                  type='date'
                  value={mainApplication.DOB}
                  onChange={(date) => handleChange({ target: { id: 'DOB', value: date } })}
                  maxDate={getMinDOB()}
                  minDate={getMaxDOB()}
                  openToDate={mainApplication.DOB}
                  className='datePickerBorderLeft'
                />
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Gender</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='Gender'
                    name='Gender'
                    value={mainApplication.Gender}
                    onChange={(e) => handleChange(e)}
                >
                  {
                    collections.genders.map(gender => {
                      return (<option key={gender.id}>{gender.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Address</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Address'
                    name='Address'
                    placeholder='Enter address'
                    value={mainApplication['Address']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={mainApplication.Address}
                    inputName='Address'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>City / Town</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='City'
                    name='City'
                    placeholder='Enter city'
                    value={mainApplication.City}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={mainApplication.Address}
                    inputName='City'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>State / Province / Region</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='State'
                    name='State'
                    placeholder='Enter state'
                    value={mainApplication.State}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    value={mainApplication.State}
                    inputName='State'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Postcode</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Postcode'
                    name='Postcode'
                    placeholder='Enter postcode'
                    value={mainApplication.Postcode}
                    onChange={(e) => handleNumberFormatFields(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='numbersOnly'
                    value={mainApplication.Postcode}
                    inputName='Postcode'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Country</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                  id='Country'
                  name='Country'
                  value={mainApplication.Country}
                  onChange={(e) => handleChange(e)}
                  autoFocus
                >
                  {
                    collections.countries.map(country => {
                      return (<option key={country.id}>{country.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Email Address</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Email Address'
                    name='Email Address'
                    placeholder='Enter email address'
                    value={mainApplication['Email Address']}
                    onChange={(e) => handleChange(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='email'
                    value={mainApplication['Email Address']}
                    inputName='Email Address'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Phone Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Contact Number'
                    name='Contact Number'
                    placeholder='Enter phone number'
                    value={mainApplication['Contact Number']}
                    onChange={(e) => handleNumberFormatFields(e)}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={validator}
                    validationRules='numbersOnly'
                    value={mainApplication['Contact Number']}
                    inputName='Contact Number'
                  />
                </>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <div className='custom-q-text-primary bold my-2'>Beneficiary Details</div>
            <Row className='wordBreak'>
              <Col className='pt-2 summary-label' sm={6}>Full Name</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Full Name'
                    name='Full Name'
                    placeholder='Enter full name'
                    onChange={(e) => handleChange(e, isBeneficiary)}
                    value={beneficiaries ? beneficiaries['Full Name'] : ''}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={beneficiaryValidator}
                    validationRules='full_name_valid_characters'
                    value={beneficiaries ? beneficiaries['Full Name'] : ''}
                    inputName='Full Name'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Relationship</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                    id='Relationship'
                    name='Relationship'
                    onChange={(e) => handleChange(e, isBeneficiary)}
                    value={beneficiaries ? beneficiaries['Relationship'] : ''}
                    autoFocus
                >
                  <option key={"defaultOption"} id={'defaultOption'}>
                    Select Option / ជ្រើសរើសប្រភេទទំនាក់ទំនង
                  </option>
                  {
                    collections.relations.map(relation => {
                      return (<option key={relation.id}>{relation.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Type</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <Input
                  type='select'
                  id='ID Type'
                  name='ID Type'
                  onChange={(e) => handleChange(e, isBeneficiary)}
                  value={beneficiaries ? beneficiaries['ID Type'] : ''}
                  autoFocus
                >
                  <option key={"defaultOption"} id={'defaultOption'}>
                    Enter ID number / សូមបំពេញលេខអត្តសញ្ញាណបណ្
                  </option>
                  {
                    collections.idTypes.map(idType => {
                      return (<option key={idType.id}>{idType.name}</option>);
                    })
                  }
                </Input>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>ID Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='ID Number'
                    name='ID Number'
                    placeholder='Enter ID number'
                    onChange={(e) => handleChange(e, isBeneficiary)}
                    value={beneficiaries ? beneficiaries['ID Number'] : ''}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={beneficiaryValidator}
                    value={beneficiaries ? beneficiaries['ID Number'] : ''}
                    inputName='ID Number'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Email Address</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Email'
                    name='Email'
                    placeholder='Enter email address'
                    onChange={(e) => handleChange(e, isBeneficiary)}
                    value={beneficiaries ? beneficiaries['Email'] : ''}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={beneficiaryValidator}
                    validationRules='email'
                    value={beneficiaries ? beneficiaries['Email'] : ''}
                    inputName='Email'
                  />
                </>
              </Col>

              <Col className='pt-2 summary-label' sm={6}>Phone Number</Col>
              <Col className='pt-2 summary-label-2' sm={6}>
                <>
                  <Input
                    id='Contact Number'
                    name='Contact Number'
                    placeholder='Enter phone number'
                    onChange={(e) => handleNumberFormatFields(e, isBeneficiary)}
                    value={beneficiaries ? beneficiaries['Contact Number'] : ''}
                    autoFocus
                  />
                  <ValidationMessages
                    validator={beneficiaryValidator}
                    validationRules='numbersOnly'
                    value={beneficiaries ? beneficiaries['Contact Number'] : ''}
                    inputName='Contact Number'
                  />
                </>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PolicyEndorsement;
