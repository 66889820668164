import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FlashMessage from '_components/FlashMessage';
import TravelInsurancePolicyDetails from 'Products/TravelInsurance/Policies/policyDetails/PolicyDetail';
import MedicalInsurancePolicyDetails from 'Products/MedicalInsurance/policyDetail';
import MotorInsurancePolicyDetails from 'Products/MotorInsurance/policyDetail';
import PersonalAccidentPolicyDetails from 'Products/PersonalAccident/policyDetail';
import MyMotorInsurancePolicyDetails from 'Products/MyMotorInsurance/policyDetail';
import HreTravelInsurancePolicyDetails from 'Products/HreTravelInsurance/Policies/policyDetails/PolicyDetail';
import CashForHospitalizationPolicyDetails from 'Products/CashForHospitalization/policy';
import WalletInsurancePolicyDetails from 'Products/WalletInsurance/policy';
import AlfalahTravelInsurancePolicyDetails from 'Products/AlfalahTravelInsurance/Policies/policyDetails/PolicyDetail';
import CBTravelPolicyDetail from 'Products/CBTravelInsurance/policyDetail';
import CBFlexiPaDetail from 'Products/CBFlexiPa/policyDetail';
import ICarePolicyDetail from '@products/gbsn/i-care/policy-detail';
import IMedPolicyDetail from '@products/gbsn/i-med/policy-detail';
import IProtectPolicyDetail from '@products/gbsn/i-protect/policy-detail';
import HgiPolicyDetail from 'Products/hgiInsurance/policyDetail';
import ABCTravelInsurancePolicyDetails from 'Products/ABCTravelInsurance/policyDetail';
import AutoInsurancePolicyDetails from '../../AutoInsurance/Policy/policyDetail'
import { policyModel } from 'models';
import config from '_config';
import { policyActions } from '../policyListing/actions';


const PolicyDetail = (props) => {
  const [productCode, changeProductCode] = useState(null);
  useEffect(() => {
    const { match: { params } } = props;
    props.clearPolicyDetail();
    props.getDetail(params.id);
  }, []);

  useEffect(() => {
    if (props.policy && props.policy.status) {
      // this is here temporary till all certificates have product code.
      const productCode = props.policy.productCode || config.products.travelInsurance.id;
      changeProductCode(Number(productCode));
    }
  }, [props.policy]);

  if (productCode && props.policy) {
    switch (productCode) {
      case config.products.travelInsurance.id:
        return <TravelInsurancePolicyDetails {...props} />;
      case config.products.hreTravelInsurance.id:
        return <HreTravelInsurancePolicyDetails {...props} />;
      case config.products.cashForHospitalization.id:
        return <CashForHospitalizationPolicyDetails {...props} />;
      case config.products.walletInsurance.id:
        return <WalletInsurancePolicyDetails {...props} />;
      case config.products.alfalahTravelInsurance.id:
        return <AlfalahTravelInsurancePolicyDetails {...props} />;
      case config.products.cbTravelInsurance.id:
        return <CBTravelPolicyDetail {...props} model={policyModel.travelCB} />;
      case config.products.flexiPa.id:
        return <CBFlexiPaDetail {...props} model={policyModel.flexiPa} />;
      case config.products.gbsnICare.id:
        return <ICarePolicyDetail {...props} model={policyModel.gbsnICare} />;
      case config.products.gbsnIMed.id:
        return <IMedPolicyDetail {...props} model={policyModel.gbsnIMed} />;
      case config.products.gbsnIProtect.id:
        return <IProtectPolicyDetail {...props} model={policyModel.gbsnIProtect} />;
      case config.products.hgiInsurance.id:
        return <HgiPolicyDetail {...props} model={policyModel.travelHGI} />;
      case config.products.medicalInsurance.id:
        return <MedicalInsurancePolicyDetails {...props} model={policyModel.medicalInsurance}  />;
      case config.products.motorInsurance.id:
        return <MotorInsurancePolicyDetails {...props} model={policyModel.motorInsurance}  />;
      case config.products.personalAccident.id:
        return <PersonalAccidentPolicyDetails {...props} model={policyModel.personalAccident}  />;
      case config.products.myMotorInsurance.id:
        return <MyMotorInsurancePolicyDetails {...props} model={policyModel.myMotorInsurance} />;
      case config.products.abcTravelInsurance.id:
        return <ABCTravelInsurancePolicyDetails {...props} model={policyModel.travelCB} />;
      case config.products.autoInsurance.id:
        return <AutoInsurancePolicyDetails {...props}/>;
      default:
        return <FlashMessage flashMessage='unknown product code' />;
    }
  }
  return (
    <div className='loader' />
  );
};

function mapStateToProps(state) {
  const { policy } = state.policyManagement;
  return { policy };
}

function mapDispatchToProps(dispatch) {
  return {
    getDetail: (policyId) => dispatch(policyActions.getDetail(policyId)),
    clearPolicyDetail: () => dispatch(policyActions.clearPolicyDetail()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyDetail);
