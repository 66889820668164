import React, {useEffect} from 'react';
import {Col, Row} from "reactstrap";
import RenderFields from "../../../@containers/getQuote/loadableComponents/repo/renderFields";
import lib from "../../../utils/lib";

const BasicDetails =(props:ILoadableComponentProps)=>{
    const {
        model: {
            collections,
            item,
            validationErrors,
            model,
            onChange,
            db
        },
    } = props;
    let basicDetailModelFields = [...model.basicDetailFields.fields]

    if(item?.['properties']?.['Coverage Type'] === 'Third Party Liability'){
        basicDetailModelFields = basicDetailModelFields.filter((el)=>{
            return el.id !== 'properties.Sum Insured'
        });
    }

    const changeHandler=(pairs: IPair[])=>{
        const {id ,value} = pairs[0];
        let additionalPair:IPair[]= [];
        if( id === 'properties.Coverage Type'){
            additionalPair = [
                {
                    id:'properties.Sum Insured',
                    value: value === 'Third Party Liability' ? 0 : ''
                }
            ]
        }
        onChange('onChange', {pairs:[
                ...pairs,
                ...additionalPair
            ]})
    }


    return (
        <Row>
            <Col md='12'>
                <h5 className='sub-title'>{lib.withKhmerText(model.title,db)}</h5>
                <RenderFields
                    onChange={changeHandler}
                    collections={collections}
                    item={item}
                    ext={false}
                    validationErrors={validationErrors}
                    fields={basicDetailModelFields}
                />
            </Col>
        </Row>
    )
}

export default BasicDetails;