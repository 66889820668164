import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { policyActions } from "Products/shared/policyListing/actions";
import { store } from "_helpers";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  reissueable,
  endorseable,
  downloadable,
  cancelable,
} from "../Utils/util";

const ActionButton = (props: {
  type: "quotation" | "policy";
  data: any;
  editQuotation?: () => void;
  open?: boolean;
  toggle?: () => void;
  toggleEndorseMode?:()=>void;
  cancelPolicy?:()=>void;
}) => {
  const { editQuotation, type, data, open, toggle,toggleEndorseMode,cancelPolicy} = props;
  const dispatch = useDispatch();

  const policyDownloadHandler = async () => {
    const res: any = await dispatch(policyActions.downloadPolicy(data.id));
    if (res.blob) {
      const url = window.URL.createObjectURL(new Blob([res.blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `policy-${data.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      dispatch(policyActions.clearBlobPolicy());
    }
  };

  const showForbiddenMessage = (message?: string) => {
    return store.dispatch({
      type: "SHOW_FORBIDDEN_MESSAGE",
      payload: message,
    });
  };

  const reissuePolicyHandler = async () => {
    const isPermitted: any = await dispatch(policyActions.hasPermission("Reissue Certificate"));
    if (!isPermitted) {
      showForbiddenMessage();
      return;
    }
    dispatch(policyActions.reissuePolicy(data.id));
  };

  const getButton = () => {
    switch (type) {
      case "policy":
        return (
          <Dropdown className="mb-3 text-right" isOpen={open} toggle={toggle}>
            <DropdownToggle caret className="btn-action btn-action-primary">
              Actions
            </DropdownToggle>
            <DropdownMenu>
              {downloadable(data) ? (
                  <DropdownItem
                    onClick={() => {
                      policyDownloadHandler()
                    }}
                  >
                    Download Certificate
                  </DropdownItem>
              ):null}
              {!downloadable(data) ? (
                      <DropdownItem
                          onClick={() => {
                            policyDownloadHandler()
                          }}
                      >
                        Download Cancellation Confirmation
                      </DropdownItem>
                  ):null}
              {reissueable(data) ? (
                <DropdownItem
                  onClick={() => {
                    reissuePolicyHandler()
                  }}
                >
                  Reissue Certificate
                </DropdownItem>
              ):null}
              {endorseable(data) ? (
                <DropdownItem
                  onClick={() => {
                    toggleEndorseMode && toggleEndorseMode();
                  }}
                >
                  Endorse Certificate
                </DropdownItem>
              ):null}
              {cancelable(data) ? (
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    cancelPolicy && cancelPolicy();
                  }}
                >
                  Cancel Certificate
                </DropdownItem>
              ):null}
            </DropdownMenu>
          </Dropdown>
        );
      case "quotation":
        return (
          <div className="mb-3 text-right">
            <Button
              className={
                data.state === "Completed"
                  ? "customPrimarybtn d-none"
                  : "customPrimarybtn btn-primary"
              }
              onClick={() => {
                editQuotation && editQuotation();
              }}
            >
              Edit Quotation
            </Button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return getButton();
};

export default ActionButton;
