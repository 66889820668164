import React from "react";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import getQuoteGraphic from "../../images/bannerGraphicImages/get-quote.png";
import { TitleComponent } from "_components";
import { DetailTitle, SidebarCard,PaymentCard,RemarksCard } from "@xc-core/components";
import InsuredDetails from "./insuredDetails";
import { isDatePassed } from "utils";
import { checkPermission } from "_services";
import { store } from "_helpers";
import ActionButton from "./ActionButton";
import {Loader} from "../../@xc-core/components/core";

interface IProps {
  history?: any;
  data: any;
  sidebarModel: any;
  editQuotation: () => void;
  components: {
    title: string;
    model: any;
    type: "primary" | "secondary";
  }[];
  currencySymbol?: string;
  productIcon?: string;
  title?: string;
  type: "quotation" | "policy" | 'summary';
  open?: boolean;
  toggle?: () => void;
  endorseMode?: boolean;
  toggleEndorseMode?: () => void;
  downloadPolicy?: (id: string) => void;
  reIssuePolicy?: () => void;
  cancelPolicy?: () => void;
  collections?:any;
  validator?:any;
  errorMessage?:any;
  isEndorsementAllowed?:(type:string)=>void;
  onChange?:(pair:IPair,isPayment?:boolean)=>void;
  endorsementCheck?:()=>void;
  paymentForm?:any;
  submitPayment?:()=>void;
  sendingRequest?:boolean,
  greyBoxTitle?: string;
  isKhmer?:boolean;
  showRemarks?:boolean;
}


const RenderBody = (props: any) => {
  const { quotation, components,endorseMode ,onChange,collections,errorMessage,greyBoxTitle} = props;
  return (
    <>
      <InsuredDetails greyBoxTitle={greyBoxTitle} item={quotation} components={components} endorseMode={endorseMode} onChange={onChange} collections={collections}  errorMessage={errorMessage} />
    </>
  );
};

const RenderPageButtons = (props:any) => {
  const {
    toggleEndorseMode,
    handleBackToListing,
    endorseMode,
    submitEndorsementCheck,
    disableSubmit = false,
  } = props;
  return (
      <div className='text-right'>
        { endorseMode
            ? (
                <div>
                  <Button
                      color='muted'
                      className='my-4 mr-4 customLeastbtn'
                      onClick={toggleEndorseMode}
                  >
                    Cancel
                  </Button>
                  <Button
                      color='primary'
                      className='my-4 customPrimarybtn'
                      onClick={submitEndorsementCheck}
                      disabled={disableSubmit}
                  >
                    Update & Reissue Certificate
                  </Button>
                </div>
            ) : (
                <Button
                    color='info'
                    className='my-4 customInfobtn'
                    onClick={handleBackToListing}
                >
                  Back to Listing
                </Button>
            )}
      </div>
  );
};

const Details = (props: IProps) => {
  const {
    history,
    data,
    sidebarModel,
    components,
    productIcon,
    currencySymbol,
    title,
    endorseMode,
    toggleEndorseMode,
    toggle,
    cancelPolicy,
    open,
    type,
      collections,
      validator,
    errorMessage,
      onChange,
    isEndorsementAllowed,
      endorsementCheck,
    paymentForm,
    submitPayment,
    sendingRequest,
      greyBoxTitle,
      isKhmer,
      showRemarks
  } = props;
  const pathName = history?.location.pathname;
  const backPath = pathName?.substr(0, pathName.lastIndexOf("/"));

  const showForbiddenMessage = (message: string) => {
    return store.dispatch({
      type: "SHOW_FORBIDDEN_MESSAGE",
      payload: message,
    });
  };

  const editQuotation = async () => {
    const urlData = {
      pathname: `/editQuotation/${data.productCode}/${data.id}`,
      state: "reset",
    };
    const startDate = data.properties["Start Date"];
    const { status } = (await checkPermission("Edit Quotation")) as any;

    if (isDatePassed(startDate) || status !== "permitted") {
      showForbiddenMessage("This quotation is outdated and cannot be edited.");
      return;
    }

    history?.push(urlData);
  };

  const getComponent = () => {
    switch (type) {
      case "summary":
        return (
            <>
              <Col sm={8}>
                <RenderBody quotation={data} components={components} greyBoxTitle={greyBoxTitle} />
              </Col>
              <Col sm={4}>
                <SidebarCard.Quotation
                    item={data}
                    model={sidebarModel}
                    customIconProduct={productIcon || "icon-motor-insurance"}
                    currencySymbol={currencySymbol}
                    ext={isKhmer}
                />
              </Col>
            </>
        )
      case "quotation":
        return (
          <>
            <Col sm={8}>
              <DetailTitle.Quotation quotation={data} />
              <RenderBody quotation={data} components={components} greyBoxTitle={greyBoxTitle}/>
            </Col>
            <Col sm={4}>
              <ActionButton
                  type={type}
                  data={data}
                  editQuotation={editQuotation}
              />
              <SidebarCard.Quotation
                item={data}
                model={sidebarModel}
                customIconProduct={productIcon}
                currencySymbol={currencySymbol}
              />
            </Col>
          </>
        );
      case "policy":
        return (
          <>
            <Col sm={8}>
              <DetailTitle.Policy policy={data} />
              <RenderBody
                  quotation={data}
                  components={components}
                  endorseMode={endorseMode}
                  onChange={onChange}
                  collections={collections}
                  errorMessage={errorMessage}
                  greyBoxTitle={greyBoxTitle}
              />
              <RenderPageButtons
                  toggleEndorseMode={toggleEndorseMode}
                  submitEndorsementCheck={()=>{endorsementCheck && endorsementCheck()}}
                  handleBackToListing={()=>history?.push('/policies')}
                  disableSubmit={false}
                  endorseMode={endorseMode}
              />
            </Col>
            <Col sm={4}>
              <ActionButton
                type={type}
                data={data}
                open={open}
                toggle={toggle}
                cancelPolicy={cancelPolicy}
                toggleEndorseMode={toggleEndorseMode}
              />
              <SidebarCard.Policy
                status={data.status}
                model={sidebarModel}
                item={data}
                collections={collections}
                validator={() => validator()}
                endorseMode={endorseMode}
                handleChange={onChange}
                errorMessages={errorMessage}
                isEndorsementAllowed={isEndorsementAllowed}
                customIconProduct={productIcon}
                currencySymbol={currencySymbol}
              />
              <PaymentCard
                  validator={validator}
                  collections={collections}
                  item={paymentForm.properties}
                  resetPaymentDetailsForm={()=>{}}
                  handleSubmit={submitPayment}
                  handleInputChange={(e:any)=>{
                     onChange && onChange({id:e.target.id, value:e.target.value},true)
                  }}
                  notification={''}
              />
              {
                showRemarks !==  false ? <RemarksCard
                    properties={data ? data.properties : {}}
                    status={data ? data.status : ''}
                /> : null
              }
            </Col>
          </>
        );
    }
  };

  return (
    <div className="container-fluid px-0">
      {
        type !== 'summary' && <TitleComponent
              title={title || "Quotation Details"}
              bannerGraphic={getQuoteGraphic}
              backTitle="Back to listing"
              backPath={backPath}
              history={history}
          />
      }
      {sendingRequest && <Loader/>}
      <div
        className={type === 'summary' ?"row d-flex ml-1":"row d-flex justify-content-center my_50"}
        id="quotation-detail"
      >
        <Col sm={11} className="quotation-detail-wrapper">
          <Row>{data && getComponent()}</Row>
        </Col>
      </div>
    </div>
  );
};

export default Details;
