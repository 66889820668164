import _, {filter, includes, uniq} from 'lodash';

/**
 * get the value from object by its path
 * @param {any} obj
 * @param {string} path
 * @returns {any} value for path in object
 */
const getValueFromPath = (obj: any, path: string): any => {
  if (!path) return null;
  return path.split('.').reduce((previous, current) => (previous ? previous[current] : null), obj);
};

// const setValueInPathMutate = (obj: any, path: string, value: any): any => {
//   const pList = path.split('.');
//   const key = pList.pop();
//   const pointer = pList.reduce((accumulator, currentValue) => {
//     if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
//     return accumulator[currentValue];
//   }, obj);
//   if (key) pointer[key] = value;
//   return obj;
// };

const JsonDeepClone = (obj: any): any => JSON.parse(JSON.stringify(obj)) as any;

const getPair = (id: string, value: any): IPair => ({ id, value } as IPair);

const setValueWithLodash = (obj: any, path: string | string[], value: any): any => {
  const clonedObj = _.cloneDeep(obj);
  _.set(clonedObj, path, value);
  return clonedObj;
};

const getValueWithLodash = (obj: any, path: string | string[]): any => _.get(obj, path);

const isEmpty = (obj: IObject): boolean => {
  if (!obj) return true;
  return obj.constructor === Object && Object.keys(obj).length === 0;
};

const getType = (item:IObject,id:string) : string =>{
  return getValueWithLodash(item,id.slice(0,id.lastIndexOf(".")) + ".Type");
}

const deepMergeWithLodash = (obj:IObject, source:IObject) :any => _.merge(obj,source);

const deleteKeyWithLodash = (obj: any, path: string | string[]): any => _.omit(obj, path);

const commaSeparator=(text:string)=>{
  return text.split(',').filter((el)=>el !== "").map((el)=>el.trim());
}

const getDuplicatesValue=(list:string[])=>{
  return uniq(filter(list.map((el)=>el), (val, i, iteratee) => includes(iteratee, val, i + 1)))
}

const obj = {
  getValueFromPath,
  getPair,
  JsonDeepClone,
  setValueWithLodash,
  getValueWithLodash,
  isEmpty,
  getType,
  deepMergeWithLodash,
  deleteKeyWithLodash,
  commaSeparator,
  getDuplicatesValue
};
export default obj;
