import React from 'react';
import {
  Switch, Route, withRouter, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';

//--------------
import * as routes from '@xc-core/routes';
import { ProductSelection, EditQuoteNew, RenderWizards } from '@containers/getQuote'; // initial test on base mode //need to be complete
import { QuotationList } from '@containers/quotation'; // initial test on base mode //need to be complete
import { PolicyList } from '@containers/policy'; // initial test on base mode //need to be complete
import { LeadList, LeadInfo } from '@containers/lead';
import { AuditLogList, AuditLogDetails } from '@containers/auditLog'; // initial test on base mode //need to be complete
import { PassManageDetails } from '@containers/passwordManagement';
import { DataDump } from '@containers/data-dump';
// import { OrganizationList } from '@containers/organization';
// import { UserList } from '@containers/user';
// import { UserGroupList } from '@containers/userGroup';

//--------------

import { currentUser } from 'utils';
import OrganizationsModule from '../TeamManagementModule/OrganizationModule/OrganizationModule';
import OrganizationDetails from '../TeamManagementModule/OrganizationModule/component/OrganisationDetails';
import ResourceModule from '../TeamManagementModule/ResourceCenter/ResourceCenter';
import ResourceManage from '../TeamManagementModule/ResourceCenter/component/ResourceManagement';
import Users from '../TeamManagementModule/Users/Users';
import UserManagementDetails from '../TeamManagementModule/Users/component/UserManagementDetails';
import UserGroups from '../TeamManagementModule/UserGroups/UserGroups';
import UserGroupDetails from '../TeamManagementModule/UserGroups/component/UserGroupDetails';
// import SelectProduct from '../Products/shared/GetQuote/SelectProduct';
// import EditQuotation from '../Products/shared/EditQuote/EditQuotation';
import UserProfileDetails from '../TeamManagementModule/UserProfile/UserProfileDetails';
import DocumentationModule from '../TeamManagementModule/DocumentationModule';
import DashboardComponent from '../TeamManagementModule/DashboardModule';
import ProductsModule from '../TeamManagementModule/ProductsModule/products';
// import Quotations from '../Products/shared/quotationListing';
import QuotationDetailsStack from '../Products/shared/quotationDetails/quotationDetailsStack';
import PaymentFailed from '../Products/shared/paymentFailed/paymentFailed';
// import Policies from '../Products/shared/policyListing';
import PolicyDetailsStack from '../Products/shared/policyDetails/policyDetailsStack';
import RenderProductsWizard from '../Products/shared/GetQuote/RenderProductsWizard';
import { SystemConfigsModule } from '@containers/SystemConfigsModule'; // initial test on base mode //need to be complete
import { FiscalYearModule } from '@containers/FiscalYearModule';
import { DistributionControlList, DistributionControlDetail } from '@containers/DistributionControl';
import {CollectionManagement} from '@containers/CollectionManagement'; // initial test on base mode //need to be complete
import { ScopeManagementList, EditScope } from '@containers/ScopeManagement';
import {BulkCancellation,BulkCancellationPreviewPage,ThankYouPage,FailPage} from "../@containers/bulkCancellation";

// ===========================================
function NewQuotationStack(props) {
  return (
    <Switch>
      <Route exact path={`/${routes.quotation.base}`} component={() => <QuotationList history={props.history} />} />
      <Route path={`/${routes.quotation.base}/:${routes.quotation.detailParam}`} component={QuotationDetailsStack} />
    </Switch>
  );
}

function NewPolicyStack(props) {
  return (
    <Switch>
      <Route exact path={`/${routes.policy.base}`} component={() => <PolicyList history={props.history} />} />
      <Route path={`/${routes.policy.base}/:${routes.policy.detailParam}`} component={PolicyDetailsStack} />
    </Switch>
  );
}
function LeadsStack(props) {
  return (
    <Switch>
      <Route exact path={`/${routes.lead.base}`} component={() => <LeadList history={props.history} />} />
      <Route exact path={`/${routes.lead.base}/:${routes.lead.detailParam}`} component={() => <LeadInfo history={props.history} />} />
    </Switch>
  );
}
function AuditStack(props) {
  return (
    <Switch>
      <Route path={`/${routes.audit.base}`} exact component={() => <AuditLogList history={props.history} />} />
      <Route
        exact
        path={`/${routes.audit.base}/:${routes.audit.detailParam}`}
        // path={`/${routes.audit.base}`}
        component={AuditLogDetails}
      />
    </Switch>
  );
}
function ScopeManagementStack() {
  return (
    <Switch>
      <Route exact path='/authorization/resources/tree/edit/:name' component={EditScope} />
      <Route exact path='/authorization/resources/tree/add' component={EditScope} />
      <Route exact path='/authorization/resources/tree' component={ScopeManagementList}></Route>
    </Switch>
  )
}

// function NewOrganizationStack(props) {
//   return (
//     <Switch>
//       <Route exact path={`/${routes.organization.base}`} component={() => <OrganizationList history={props.history} />} />
//       <Route path={`/${routes.organization.base}/:${routes.organization.detailParam}`} component={OrganizationDetails} />
//     </Switch>
//   );
// }

// function NewUserStack(props) {
//   return (
//     <Switch>
//       <Route exact path={`/${routes.user.base}`} component={() => <UserList history={props.history} />} />
//       <Route path={`/${routes.user.base}/:${routes.user.detailParam}`} component={UserManagementDetails} />
//     </Switch>
//   );
// }

// function NewUserGroupStack(props) {
//   return (
//     <Switch>
//       <Route exact path={`/${routes.userGroup.base}`} component={() => <UserGroupList history={props.history} />} />
//       <Route path={`/${routes.userGroup.base}/:${routes.userGroup.detailParam}`} component={UserGroupDetails} />
//     </Switch>
//   );
// }
// ===========================================
function GetQuoteStack(props) {
  return (
    <Switch>
      {/* <Route exact path="/getQuote" component={SelectProduct} /> */}
      {/* <Route path="/getQuote/:name" component={RenderProductsWizard} /> */}
      <Route exact path='/getQuote' component={ProductSelection} />
      <Route path='/getQuote/:name' component={RenderWizards} />
    </Switch>
  );
}
function EditQuoteStack() {
  return (
    <Switch>
      <Route exact path='/editQuotation/:productCode/:quotationId' component={EditQuoteNew} />
      {/* <Route exact path="/editQuotation/:productCode/:quotationId" component={EditQuotation} /> */}
    </Switch>
  );
}
function FailedQuoteStack() {
  return (
    <Switch>
      <Route exact path='/failedQuotation/:id' component={PaymentFailed} />
      {/* <Route exact path="/editQuotation/:productCode/:quotationId" component={EditQuotation} /> */}
    </Switch>
  );
}
function UserProfileStack() {
  return (
    <Switch>
      <Route exact path='/profile' component={UserProfileDetails} />
    </Switch>
  );
}
function ProductsStack() {
  return (
    <Switch>
      <Route exact path='/products' component={ProductsModule} />
    </Switch>
  );
}
function DocumentationStack() {
  return (
    <Switch>
      <Route path='/' component={DocumentationModule} />
    </Switch>
  );
}
function DashboardStack() {
  return (
    <Switch>
      <Route path='/dashboard' component={DashboardComponent} />
    </Switch>
  );
}

// function PolicyStack(props) {
//   return (
//     <Switch>
//       <Route exact path="/policies" component={() => <Policies history={props.history} />} />
//       <Route path="/policies/:id" component={PolicyDetailsStack} />
//     </Switch>
//   );
// }
// PolicyStack.propTypes = {
//   history: PropTypes.object.isRequired,
// };

// function QuotationStack(props) {
//   return (
//     <Switch>
//       <Route exact path="/quotations" component={() => <Quotations history={props.history} />} />
//       <Route path={"/quotations/today"} component={() =>
//        <Quotations history={props.history} />}/>
//       <Route path="/quotations/:id" component={QuotationDetailsStack} />
//     </Switch>
//   );
// }
// QuotationStack.propTypes = {
//   history: PropTypes.object.isRequired,
// };

function OrganizationStack(props) {
  return (
    <Switch>
      <Route exact path='/organizationsManagement' component={() => <OrganizationsModule history={props.history} />} />
      <Route path='/organizationsManagement/:name' component={OrganizationDetails} />
    </Switch>
  );
}

OrganizationStack.propTypes = {
  history: PropTypes.object.isRequired,
};
function ResourceStack(props) {
  return (
    <Switch>
      <Route exact path='/resourceCenter' component={() => <ResourceModule history={props.history} />} />
      <Route path='/resourceCenter/manage' component={ResourceManage} />
    </Switch>
  );
}

ResourceStack.propTypes = {
  history: PropTypes.object.isRequired,
};

function UsersStack(props) {
  return (
    <Switch>
      <Route exact path='/userManagement' component={() => <Users history={props.history} />} />
      <Route path='/userManagement/:name' component={UserManagementDetails} />
    </Switch>
  );
}
UsersStack.propTypes = {
  history: PropTypes.object.isRequired,
};

function UserGroupStack(props) {
  return (
    <Switch>
      <Route exact path='/userGroup' component={() => <UserGroups history={props.history} />} />
      <Route path='/userGroup/:name' component={UserGroupDetails} />
    </Switch>
  );
}

function SystemConfigsStack(){
  return (
    <Switch>
      <Route exact path='/system-configuration' component={SystemConfigsModule} />
    </Switch>
  );
}
function FiscalYearStack() {
  return (
    <Switch>
      <Route exact path='/fiscalyear' component={FiscalYearModule} />
    </Switch>
  );
}

function DistributionControlStack() {
  return (
    <Switch>
      <Route exact path='/PolicyLimitConfig' component={DistributionControlList}></Route>
      <Route exact path='/PolicyLimitConfig/:id' component={DistributionControlDetail} />
    </Switch>
  )
}

function CollectionManagementStack(){
  return (
  <Switch>
    <Route exact path='/collections' component={CollectionManagement}></Route>
  </Switch>
  )
}

function BulkCancellationStack(props){
    return (
        <Switch>
            <Route exact={true} path={`/bulkCancellation`} component={()=><BulkCancellation history={props.history}/>} />
            <Route exact={true} path={`/bulkCancellation/previewPage`} component={()=><BulkCancellationPreviewPage history={props.history}/>} />
            <Route exact={true} path={`/bulkCancellation/complete`} component={()=><ThankYouPage history={props.history}/>} />
            <Route exact={true} path={`/bulkCancellation/failed`} component={()=><FailPage history={props.history}/>} />
        </Switch>
    )
}

UserGroupStack.propTypes = {
  history: PropTypes.object.isRequired,
};

const Main = (props) => {
  const { modules } = currentUser;
  return (
    <main>
      <Switch>
        <Route path={`/${routes.quotation.base}`} component={() => <NewQuotationStack history={props.history} />} />
        <Route path={`/${routes.policy.base}`} component={() => <NewPolicyStack history={props.history} />} />
        <Route path={`/${routes.lead.base}`} component={() => <LeadsStack history={props.history} />} />
        <Route path={`/${routes.audit.base}`} component={() => <AuditStack history={props.history} />} />
        {/* <Route path={`/${routes.organization.base}`} component={() => <NewOrganizationStack history={props.history} />} /> */}
        {/* <Route path={`/${routes.user.base}`} component={() => <NewUserStack history={props.history} />} /> */}
        {/* <Route path={`/${routes.userGroup.base}`} component={() => <NewUserGroupStack history={props.history} />} /> */}
        {/* <Route path="/quotations" component={() => <QuotationStack history={props.history} />} /> */}
        {/* <Route path="/policies" component={() => <PolicyStack history={props.history} />} /> */}
        <Route path='/organizationsManagement' component={() => <OrganizationStack history={props.history} />} />
        <Route path='/resourceCenter' component={() => <ResourceStack history={props.history} />} />
        <Route path='/userManagement' component={() => <UsersStack history={props.history} />} />
        <Route path='/userGroup' component={() => <UserGroupStack history={props.history} />} />
        <Route path='/configs' component={() => <PassManageDetails history={props.history} />} />
        <Route path='/data-dump' component={() => <DataDump history={props.history} />} />

        <Route path='/getQuote' component={GetQuoteStack} />
        <Route path='/editQuotation' component={EditQuoteStack} />
        <Route path='/failedQuotation' component={FailedQuoteStack} />
        <Route path='/profile' component={UserProfileStack} />
        <Route path='/products' component={ProductsStack} />
        <Route path='/documentation' component={DocumentationStack} />
        <Route path='/dashboard' component={DashboardStack} />
        <Route path='/system-configuration' component={SystemConfigsStack} />
        <Route path='/fiscalyear' component={FiscalYearStack} />
        <Route path='/PolicyLimitConfig' component={DistributionControlStack} />
        <Route path='/collections' component={CollectionManagementStack} />
        <Route path='/authorization/resources/tree' component={ScopeManagementStack} />
          <Route path={`/bulkCancellation`} component={()=><BulkCancellationStack history={props.history}/>} />
        <Redirect to={`${modules[0].link}`} />
      </Switch>
    </main>
  );
};
Main.propTypes = {
  history: PropTypes.object.isRequired,
};



export default withRouter(Main);
